import { Button, Icon } from '@equinor/eds-core-react';
import cx from 'classnames';
import CSS from 'csstype';
import queryString from 'query-string';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { PreviewCase } from '../../element/cases/PreviewCase';
import { PreviewCaseFlat } from '../../element/cases/PreviewCaseFlat';
import { appInsights } from '../../services/log/AppInsights';
import { GlobalContext } from '../../state/context';
import { setSelectedCase } from '../../state/page/pageActions';
import { getUrlIncludingCase } from '../../utils/filter/filterUtils';
import { checkKeyInStorage } from '../../utils/storage/storageUtils';
import styles from './Cases.module.css';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

export const Cases = (props: IProps): JSX.Element => {
    const properties = {
        searchQuery: queryString.parse(window.location.search)['keyword'],
        searchMode: 'keyword',
        hostName: window.location.hostname
    };
    appInsights.trackEvent({ name: window.location.hostname + '_SAFEX_SEARCH', properties: properties });

    const { state, dispatch } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [useListView, setUseListView] = React.useState<boolean>(checkKeyInStorage('safex_uselistview'));

    return (
        <div className={cx(styles.Cases, props.className)} style={props.style}>
            <div className={styles.buttons}>
                <Button
                    variant="ghost_icon"
                    color="primary"
                    onClick={(): void => {
                        setUseListView(true);
                        localStorage.setItem('safex_uselistview', 'true');
                    }}
                >
                    <Icon name={'list'} title={'Primary'}></Icon>
                </Button>
                <Button
                    variant="ghost_icon"
                    color="primary"
                    onClick={(): void => {
                        setUseListView(false);
                        localStorage.setItem('safex_uselistview', 'false');
                    }}
                >
                    <Icon name={'view_module'} title={'Primary'}></Icon>
                </Button>
            </div>
            <div className={styles.content}>
                {state.searchResponse.incidents && !useListView && (
                    <>
                        {state.searchResponse.incidents.map((element) => (
                            <PreviewCase
                                key={element.id}
                                incident={element}
                                className={styles.PreviewCase}
                                onClick={(): void => {
                                    dispatch(setSelectedCase(element));
                                    navigate(getUrlIncludingCase(element.id));
                                }}
                            />
                        ))}
                    </>
                )}
                {state.searchResponse.incidents && useListView && (
                    <>
                        {state.searchResponse.incidents.map((element) => (
                            <PreviewCaseFlat
                                key={element.id}
                                incident={element}
                                className={styles.PreviewCase}
                                onClick={(): void => {
                                    dispatch(setSelectedCase(element));
                                    navigate(getUrlIncludingCase(element.id));
                                }}
                            />
                        ))}
                    </>
                )}
            </div>
        </div>
    );
};
