export default {
    SELECT_LANGUAGE: 'Select language',
    SEARCH: 'Search',
    CONTACT_SAFEX_TEAM: 'Contact SafeX Team',
    VERSION: 'Version',
    FAVORITES: 'Favorites',
    HOME: 'Home',
    HIDE_FILTERS: 'Hide filters',
    SHOW_FILTERS: 'Show filters',
    DELETE_FILTERS: 'Clear filters',
    SOURCE: 'Source',
    LOCATION: 'Location',
    DATE: 'Date',
    OTHER: 'Other',
    SEVERITY: 'Severity',
    FROM: 'From',
    TO: 'To',
    SORT_BY_DATE: 'Sort by recent',
    INSTALLATION: 'Installation',
    SHORE: 'Onshore/Offshore',
    SELECTION: 'Selection',
    ACTIVITY: 'Activity',
    WORK_PROCESS: 'Work process',
    RESPONSIBLE_UNIT: 'Responsible unit',
    QUERY_TIPS: 'Query tips',
    RISK_MOMENTS: 'Cause factors',
    IMPORT: 'Import',
    EXPORT: 'Export',
    IMPORT_EXPORT_DESCRIPTION:
        'Your favorites are stored only in this browser cache. If you want to keep them for longer or transfer them between computers you can export and import them.',
    RISK1: 'Undesired behaviour (internal and external)',
    RISK2: 'Failure/defect in system/equipment',
    RISK3: 'Defect or technical failures on component/system/plant',
    RISK4: 'Careless/unfocused/not observant/distracted',
    RISK5: 'Did not meet requirements/guidelines/instructions',
    DOWNLOAD_PDF: 'Download as PDF',
    GENERATED_ON: 'Generated on',
    REPORT_FROM: 'Report from',
    HOW_TO_SEARCH: 'How to search?',
    HOW_TO_USE_FAVORITES: 'How to use the favorites?',
    SIMPLE_SEARCH: 'Simple search',
    SEARCH_TIP_1: 'Searching for any of the words, will give result also when only one is found.',
    SEARCH_EXAMPLE_1: 'Example syntax: fire accident car',
    OPERATOR: 'operator',
    SEARCH_TIP_2:
        'Is used when all words need to appear in the search results (NB! First word is automatically required)',
    SEARCH_EXAMPLE_2: 'Example syntax: fire +accident +car',
    SEARCH_TIP_3: 'Is used where some words should not be present (e.g. should not include car: -car))',
    SEARCH_EXAMPLE_3: 'Example syntax: fire -car',
    QUOTES: 'quotes',
    SEARCH_TIP_4: 'is used to search for a specific phrase',
    SEARCH_EXAMPLE_4: 'Example syntax: &quot;car accident&quot;',
    SEARCH_TIP_5:
        'Is used if you want to search for a word which could have various extensions or be part of a longer word.',
    SEARCH_EXAMPLE_5: 'Example syntax: press*',
    SEARCH_TIP_6: 'Is used if you want to search for one word or the other.',
    SEARCH_EXAMPLE_6: 'Example syntax: fire | car',
    FAQ_FAVORITES_SAVING_QUESTION: 'How to save an incident as a favorite?',
    FAQ_FAVORITES_SAVING_ANSWER:
        'You can add an incident to your favorites by selecting a case and clicking on the Save favorite button. The incident will then appear on the Favorites page. Note that your favorites are only stored in the browser memory. To preserve them at a safer location please use the export and import functionality. The import/export functionality can also be used to share your favorites with others.',
    FAQ_FAVORITES_PDF_QUESTION: 'How to download incidents as PDF?',
    FAQ_FAVORITES_PDF_ANSWER:
        'First, add the incidents you want to download as favorites. Then go to the Favorites page through the link on the right hand side panel and click the Download as PDF button.',
    FAQ_WHAT_IS_SAFEX_QUESTION: 'What is SafeX?',
    FAQ_WHAT_IS_SAFEX_ANSWER:
        'The idea behind SafeX is to gather all types of safety incidents on one platform and make them more accessible. Currently, only Synergi and SIOP cases are included, but more may come. In addition to the webpage you are currently reading this on, SafeX consists of an API that can easily be integrated into other Equinor services.',
    FAQ_CONTACT_QUESTION: 'Who is in charge of SafeX?',
    ANALYTICS: 'Analytics',
    CLOSE: 'Close',
    SEARCH_SIMILAR: 'Find similar',
    SAVE_FAVORITE: 'Save favorite',
    REMOVE_FAVORITE: 'Remove favorite',
    SHOW_ORIGINAL_LANGUAGE: 'Show original',
    SHOW_TRANSLATED_LANGUAGE: 'Show English',
    ACTIONS: 'Actions',
    EXTERNAL_SOURCES: 'External sources',
    CASE: 'Case',
    FREQUENT_SEARCH: 'Frequent searches',
    FREQUENT_SEARCH_FIRE: 'Fire',
    FREQUENT_SEARCH_NEWEST: 'Newest',
    FREQUENT_SEARCH_SEVERE: 'Severe',
    FREQUENT_SEARCH_VIBRATIONS: 'Vibrations',
    FREQUENT_SEARCH_FALL: 'Fall',
    FREQUENT_SEARCH_COVID19: 'Covid 19',
    ADVANCED_QUERY_INFO_HEADER: 'Use operators for advanced searches',
    ADVANCED_QUERY_INFO_PLUS: 'for a required word',
    ADVANCED_QUERY_INFO_MINUS: 'for words that should not occur',
    ADVANCED_QUERY_INFO_VERTICAL_BAR: 'for either',
    ADVANCED_QUERY_INFO_QUOTES: 'for exact matched text',
    ADVANCED_QUERY_INFO_ASTERISK: 'for all incidents',
    SAFEX_DATA_INTERNAL: 'SafeX data is classified as Internal',
    CAUSE_CATEGORY: 'Cause category',
    CAUSE: 'Cause',
    HOW_REVEALED: 'How revealed',
    KEY_LESSON: 'Key lesson',
    CLASSIFICATION_COMMENT: 'Classification comment',
    COUNTRY: 'Country'
};
