import cx from 'classnames';
import CSS from 'csstype';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { InputLabel } from './InputLabel';
import styles from './SelectField.module.css';

export interface ISelectFieldOptions {
    value: string;
    label: string;
}

export interface IProps {
    options: ISelectFieldOptions[];
    selectedOptions?: ISelectFieldOptions | ISelectFieldOptions[] | null;
    label: string;
    placeholder: string;
    isMulti?: boolean;
    style?: CSS.Properties;
    className?: string;
    closeMenuOnSelect?: boolean;
    onSelectedItem?: (selectedValues: ISelectFieldOptions | ISelectFieldOptions[] | null) => void;
    onBlur?: (value: ISelectFieldOptions | ISelectFieldOptions[] | null) => void;
    onMenuClose?: (value: ISelectFieldOptions | ISelectFieldOptions[] | null) => void;
    onRemovedItem?: (value: ISelectFieldOptions | ISelectFieldOptions[] | null) => void;
}
export const SelectField = (props: IProps): JSX.Element => {
    const [value, setValue] = useState<ISelectFieldOptions | ISelectFieldOptions[] | null>(
        props.selectedOptions ? props.selectedOptions : null
    );
    useEffect(() => {
        setValue(props.selectedOptions ? props.selectedOptions : null);
    }, [props.selectedOptions]);

    return (
        <div className={cx(styles.SelectField, props.className)} style={props.style}>
            <InputLabel text={props.label}></InputLabel>
            <Select
                defaultValue={props.selectedOptions}
                value={value}
                styles={customStyles}
                placeholder={props.placeholder}
                isSearchable={true}
                isMulti={props.isMulti}
                onMenuClose={() => props.onMenuClose?.(value)}
                onBlur={() => props.onBlur?.(value)}
                closeMenuOnSelect={props.closeMenuOnSelect}
                onChange={(selected) => {
                    if (props.isMulti) {
                        if ((selected as ISelectFieldOptions[])?.length < (value as ISelectFieldOptions[])?.length) {
                            setValue(selected as ISelectFieldOptions[]);
                            props.onRemovedItem?.(selected as ISelectFieldOptions[]);
                        } else {
                            setValue(selected as ISelectFieldOptions[]);
                            props.onSelectedItem?.(selected as ISelectFieldOptions[]);
                        }
                    } else {
                        setValue(selected as ISelectFieldOptions);
                        props.onSelectedItem?.(selected as ISelectFieldOptions);
                    }
                }}
                options={props.options}
                className={styles.select}
            />
        </div>
    );
};

const customStyles = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: (base: any) => ({
        ...base,
        minHeight: 36,
        border: 'unset',
        borderRadius: '0px',
        borderBottom: '1px solid #6f6f6f',
        backgroundColor: '#f7f7f7',
        boxShadow: 'none',
        '&:hover': {
            borderBottom: '1px solid #6f6f6f'
        }
    })
};
