import { ActionType } from '../actions';

export interface Language {
    type: ActionType.Language;
    payload: {
        value: string;
    };
}

export const language = (value: string): Language => ({
    type: ActionType.Language,
    payload: {
        value: value
    }
});
