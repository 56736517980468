import { getValueInStorage } from '../storage/storageUtils';

export function determineLayoutVisibilityTypeHidden(key: string): boolean {
    const storage = getValueInStorage(key);
    if (storage) {
        return storage === 'true';
    } else {
        return false;
    }
}

export function determineLayoutVisibilityTypeDiffers(key: string): boolean {
    const storage = getValueInStorage(key);
    if (storage) {
        return storage === 'true';
    } else if (window.innerWidth >= 1260) {
        return true;
    } else {
        return false;
    }
}
