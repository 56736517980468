import cx from 'classnames';
import CSS from 'csstype';
import React from 'react';
import styles from './InputLabel.module.css';

export interface IProps {
    text: string;
    style?: CSS.Properties;
    className?: string;
}

export const InputLabel = (props: IProps): JSX.Element => {
    return (
        <div className={cx(styles.InputLabel, props.className)} style={props.style}>
            {props.text}
        </div>
    );
};
