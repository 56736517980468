import { Button, Icon } from '@equinor/eds-core-react';
import cx from 'classnames';
import CSS from 'csstype';
import React from 'react';
import styles from './FilterBox.module.css';

export interface IProps {
    title: string;
    children: React.ReactNode | React.ReactNode[];
    style?: CSS.Properties;
    className?: string;
    onDelete?: () => void;
}

export const FilterBox = (props: IProps): JSX.Element => {
    return (
        <div className={cx(styles.FilterBox, props.className)} style={props.style}>
            <div className={styles.head}>
                <span>{props.title}</span>
                <Button variant="ghost_icon" color="primary" onClick={(): void => props.onDelete?.()}>
                    <Icon name={'delete_to_trash'}></Icon>
                </Button>
            </div>
            <div className={styles.content}>{props.children}</div>
        </div>
    );
};
