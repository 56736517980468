import { Typography } from '@equinor/eds-core-react';
import { Incident } from '@sdl/sdl-api-client';
import cx from 'classnames';
import CSS from 'csstype';
import qs from 'query-string';
import React, { useContext } from 'react';
import { GlobalContext } from '../../state/context';
import { getHighlightedText } from '../../utils/highlight/highlightUtils';
import { getIncidentOneLanguage } from '../../utils/incident/incidentUtils';
import styles from './PreviewCaseFlat.module.css';
import { SeverityBadge } from './SeverityBadge';

export interface IProps {
    incident: Incident;
    style?: CSS.Properties;
    className?: string;
    onClick?: () => void;
}

export const PreviewCaseFlat = (props: IProps): JSX.Element => {
    const { state } = useContext(GlobalContext);

    const incidentOneLanguage = getIncidentOneLanguage(state.language, props.incident);
    const date =
        (incidentOneLanguage.country ? incidentOneLanguage.country + ' - ' : '') + incidentOneLanguage.caseDateString;
    const query = qs.parse(location.search);

    return (
        <div
            className={cx(
                styles.PreviewCaseFlat,
                props.className,
                state.selectedCase?.id === incidentOneLanguage.id ? styles.selected : ''
            )}
            style={props.style}
            onClick={(): void => props.onClick?.()}
            tabIndex={0}
            onKeyDown={(event): void => {
                if (event.key == 'Enter') {
                    props.onClick?.();
                }
            }}
        >
            <div className={styles.head}>
                <div className={styles.left}>
                    <SeverityBadge
                        severity={incidentOneLanguage.severity}
                        className={styles.SeverityBadge}
                    ></SeverityBadge>
                    <Typography variant="h4">
                        {getHighlightedText(incidentOneLanguage.title, query['keyword'] as string)}
                    </Typography>
                </div>
                <div className={styles.right}>
                    <Typography variant="body_short">{date}</Typography>
                </div>
            </div>

            <div className={styles.body}>
                <Typography variant="body_long">
                    {getHighlightedText(incidentOneLanguage.description, query['keyword'] as string)}
                </Typography>
            </div>
        </div>
    );
};
