import { TextIconButton } from '@equinor/echo-components';
import { Typography } from '@equinor/eds-core-react';
import cx from 'classnames';
import CSS from 'csstype';
import React, { useContext } from 'react';
import { useSearch } from '../../hook/useSearch';
import { Localization } from '../../localization/localization';
import { GlobalContext } from '../../state/context';
import { hideFilters, searchParameters } from '../../state/page/pageActions';
import { initialPageState } from '../../state/page/pageState';
import styles from './FrequentSearches.module.css';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

export const FrequentSearches = (props: IProps): JSX.Element => {
    const { dispatch } = useContext(GlobalContext);
    const searchHook = useSearch();
    return (
        <div className={cx(styles.FrequentSearches, props.className)} style={props.style}>
            <Typography variant="h5" className={styles.title}>
                {Localization.FREQUENT_SEARCH}
            </Typography>
            <div className={styles.icons}>
                <TextIconButton
                    icon={'history'}
                    title={Localization.FREQUENT_SEARCH_NEWEST}
                    onClick={() => {
                        dispatch(hideFilters(true));
                        const params = {
                            ...initialPageState.searchParameters,
                            searchString: '*',
                            sortByDate: true
                        };
                        dispatch(searchParameters(params));
                        searchHook.search(params);
                    }}
                ></TextIconButton>

                <TextIconButton
                    icon={'hospital'}
                    title={Localization.FREQUENT_SEARCH_SEVERE}
                    onClick={() => {
                        dispatch(hideFilters(true));
                        const params = {
                            ...initialPageState.searchParameters,
                            searchString: '*',
                            severityFilter: ['1', '2']
                        };
                        dispatch(searchParameters(params));
                        searchHook.search(params);
                    }}
                ></TextIconButton>

                <TextIconButton
                    icon={'flame'}
                    title={Localization.FREQUENT_SEARCH_FIRE}
                    onClick={() => {
                        dispatch(hideFilters(true));
                        const params = {
                            ...initialPageState.searchParameters,
                            searchString: Localization.FREQUENT_SEARCH_FIRE
                        };
                        dispatch(searchParameters(params));
                        searchHook.search(params);
                    }}
                ></TextIconButton>
                <TextIconButton
                    icon={'waves'}
                    title={Localization.FREQUENT_SEARCH_VIBRATIONS}
                    onClick={() => {
                        dispatch(hideFilters(true));
                        const params = {
                            ...initialPageState.searchParameters,
                            searchString: Localization.FREQUENT_SEARCH_VIBRATIONS
                        };
                        dispatch(searchParameters(params));
                        searchHook.search(params);
                    }}
                ></TextIconButton>

                <TextIconButton
                    icon={'warning_outlined'}
                    title={Localization.FREQUENT_SEARCH_FALL}
                    onClick={() => {
                        dispatch(hideFilters(true));
                        const params = {
                            ...initialPageState.searchParameters,
                            searchString: Localization.FREQUENT_SEARCH_FALL
                        };
                        dispatch(searchParameters(params));
                        searchHook.search(params);
                    }}
                ></TextIconButton>

                <TextIconButton
                    icon={'scatter_plot'}
                    title={Localization.FREQUENT_SEARCH_COVID19}
                    onClick={() => {
                        dispatch(hideFilters(true));
                        const params = {
                            ...initialPageState.searchParameters,
                            searchString: 'Covid'
                        };
                        dispatch(searchParameters(params));
                        searchHook.search(params);
                    }}
                ></TextIconButton>
            </div>
        </div>
    );
};
