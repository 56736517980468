import { Filter, SearchRequest } from '@sdl/sdl-api-client';
import moment from 'moment';
import { EFacet, getFacetKey } from '../../config/dataConfig';
import { initialPageState, ISearchParameters } from '../../state/page/pageState';

export const getSearchParametersFromUrl = (url: string): ISearchParameters => {
    const query = new URLSearchParams(url);
    const searchParams = { ...initialPageState.searchParameters };

    searchParams.respUnitFilter = [...query.getAll('resp_unit_level1_org')];
    searchParams.workProcessFilter = [
        ...query.getAll('work_process_level1_org'),
        ...query.getAll('work_process_level1_eng'),
        ...query.getAll('work_process_level1_por')
    ];
    searchParams.activityFilter = [
        ...query.getAll('activity_level1_org'),
        ...query.getAll('activity_level1_eng'),
        ...query.getAll('activity_level1_por')
    ];
    searchParams.countryFilter = [
        ...query.getAll('country_org'),
        ...query.getAll('country_eng'),
        ...query.getAll('country_por')
    ];
    searchParams.causeFilter = [
        ...query.getAll('cause_org'),
        ...query.getAll('cause_eng'),
        ...query.getAll('cause_por')
    ];
    searchParams.shoreFilter = [
        ...query.getAll('land_ocean_org'),
        ...query.getAll('land_ocean_eng'),
        ...query.getAll('land_ocean_por')
    ];
    searchParams.installationFilter = [
        ...query.getAll('installation_org'),
        ...query.getAll('installation_eng'),
        ...query.getAll('installation_por')
    ];
    searchParams.severityFilter = [...query.getAll('severity')];
    searchParams.sourceFilter = [...query.getAll('source')];

    const newSelectedDates = getDatesFromQueryString(query);
    searchParams.sortByDate = newSelectedDates.sortByDate;
    searchParams.searchDateFrom = newSelectedDates.searchDateFrom;
    searchParams.searchDateTo = newSelectedDates.searchDateTo;

    searchParams.searchString = query.get('keyword') ?? '';

    return searchParams;
};

export const getUrlFromSearchParameters = (searchParameters: ISearchParameters, language: string): string => {
    const query = new URLSearchParams();
    query.append('keyword', searchParameters.searchString);

    getFacetFilterMapping(searchParameters).forEach((x) =>
        x.filters.forEach((f) => query.append(getFacetKey(x.facet, language), f))
    );

    query.append('searchDateFrom', moment(searchParameters.searchDateFrom).format('YYYY-MM-DD'));
    query.append('searchDateTo', moment(searchParameters.searchDateTo).format('YYYY-MM-DD'));
    query.append('sortByDate', searchParameters.sortByDate.toString());

    const oldQuery = new URLSearchParams(window.location.search);
    if (oldQuery.has('case')) {
        query.append('case', oldQuery.get('case')?.toString() ?? '');
    }

    return '/search?' + query.toString();
};

export const getSearchRequestFromSearchParameters = (
    searchParameters: ISearchParameters,
    language: string
): SearchRequest => {
    return {
        filters: getFilterComponent(searchParameters, language),
        query: searchParameters.searchString,
        facetLanguage: language,
        dateFrom: searchParameters.searchDateFrom,
        dateTo: searchParameters.searchDateTo,
        sortByDate: searchParameters.sortByDate
    };
};

const getDatesFromQueryString = (
    query: URLSearchParams
): {
    searchDateFrom: Date;
    searchDateTo: Date;
    sortByDate: boolean;
} => {
    return {
        searchDateFrom: moment(query.get('searchDateFrom')).isValid()
            ? moment(query.get('searchDateFrom')).toDate()
            : initialPageState.searchParameters.searchDateFrom,
        searchDateTo: moment(query.get('searchDateTo')).isValid()
            ? moment(query.get('searchDateTo')).toDate()
            : initialPageState.searchParameters.searchDateTo,
        sortByDate:
            query.get('sortByDate') === (!initialPageState.searchParameters.sortByDate).toString()
                ? !initialPageState.searchParameters.sortByDate
                : initialPageState.searchParameters.sortByDate
    };
};

const getFacetFilterMapping = (searchParameters: ISearchParameters) => {
    return [
        { facet: EFacet.RESPONSIBLE_UNIT, filters: searchParameters.respUnitFilter },
        { facet: EFacet.WORK_PROCESS, filters: searchParameters.workProcessFilter },
        { facet: EFacet.ACTIVITY, filters: searchParameters.activityFilter },
        { facet: EFacet.COUNTRY, filters: searchParameters.countryFilter },
        { facet: EFacet.CAUSE, filters: searchParameters.causeFilter },
        { facet: EFacet.SHORE, filters: searchParameters.shoreFilter },
        { facet: EFacet.INSTALLATION, filters: searchParameters.installationFilter },
        { facet: EFacet.SEVERITY, filters: searchParameters.severityFilter },
        { facet: EFacet.SOURCE, filters: searchParameters.sourceFilter }
    ];
};

const getFilterComponent = (searchParameters: ISearchParameters, language: string): Filter[] => {
    return getFacetFilterMapping(searchParameters)
        .filter((x) => x.filters.length > 0)
        .map((x) => {
            return { fieldName: getFacetKey(x.facet, language), values: x.filters } as Filter;
        });
};
