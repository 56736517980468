import { Button, Typography } from '@equinor/eds-core-react';
import { pdf } from '@react-pdf/renderer';
import { Incident } from '@sdl/sdl-api-client';
import cx from 'classnames';
import CSS from 'csstype';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { PreviewCase } from '../element/cases/PreviewCase';
import { Localization } from '../localization/localization';
import { GlobalContext } from '../state/context';
import { setSelectedCase } from '../state/page/pageActions';
import { FullCase } from '../structure/cases/FullCase';
import { PDFGeneration } from '../structure/cases/PDFGeneration';
import { MainContent } from '../structure/common/MainContent';
import { SideMenu } from '../structure/common/SideMenu';
import { ESidePanelSide, ESidePanelSize, SidePanel } from '../structure/common/SidePanel';
import { FrequentSearches } from '../structure/page/FrequentSearches';
import { PageHeader } from '../structure/page/PageHeader';
import { RiskMoments } from '../structure/page/RiskMoments';
import { addFavorites, getFavoriteIncidents } from '../utils/favorite/utilsFavorites';
import styles from './FavoritesPage.module.css';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

export const FavoritesPage = (props: IProps): JSX.Element => {
    const { state, dispatch } = useContext(GlobalContext);
    const [incidents, setIncidents] = useState<Incident[]>([]);
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        getFavoriteIncidents().then((value) => {
            setIncidents(value);
        });
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function importFavorites(e: any): void {
        e.preventDefault();
        if (!e.target.files[0]) return;
        const reader = new FileReader();
        reader.onload = async (e) => {
            const text = e['target']?.result;
            try {
                addFavorites(JSON.parse(text as string));
                getFavoriteIncidents().then((value) => {
                    setIncidents(value);
                });
            } catch {
                alert('Unable to parse this file');
            }
        };
        reader.readAsText(e.target.files[0]);
    }

    function exportFavorites(): void {
        const element = document.createElement('a');
        const file = new Blob(
            [
                JSON.stringify(
                    incidents.map((x) => {
                        return { id: x.id };
                    })
                )
            ],
            { type: 'text/plain' }
        );
        element.href = URL.createObjectURL(file);
        element.download = new Date().toString() + '_incidents.txt';
        document.body.appendChild(element);
        element.click();
    }

    return (
        <div className={cx(styles.FavoritesPage, props.className)} style={props.style}>
            <SidePanel size={ESidePanelSize.FULL} side={ESidePanelSide.LEFT}>
                <PageHeader className={styles.PageHeader}></PageHeader>
                {state.selectedCase && (
                    <FullCase
                        incident={state.selectedCase}
                        onFavoriteRemoved={(id: string) => {
                            const newIncidents = _.clone(incidents);
                            _.remove(newIncidents, (incident) => {
                                return incident.id === id;
                            });
                            setIncidents(newIncidents);
                        }}
                        onFavoriteAdded={(incident: Incident) => {
                            const newIncidents = _.clone(incidents);
                            newIncidents.push(incident);
                            setIncidents(newIncidents);
                        }}
                    ></FullCase>
                )}
                {!state.selectedCase && (
                    <>
                        <FrequentSearches className={styles.FrequentSearches} />
                        <RiskMoments className={styles.RiskMoments} />
                    </>
                )}
            </SidePanel>
            <MainContent className={styles.MainContent}>
                <div className={styles.info}>
                    <div className={styles.content}>
                        <Typography variant="body_long">{Localization.IMPORT_EXPORT_DESCRIPTION}</Typography>
                        <div className={styles.buttons}>
                            <Button
                                variant={'outlined'}
                                onClick={() => {
                                    exportFavorites();
                                }}
                            >
                                {Localization.EXPORT}
                            </Button>
                            <label className={styles.import}>
                                <input
                                    id="import"
                                    type="file"
                                    accept="text/plain"
                                    onChange={(e) => importFavorites(e)}
                                    ref={ref}
                                />
                                <Button
                                    variant={'outlined'}
                                    onClick={() => {
                                        ref?.current?.click();
                                    }}
                                >
                                    {Localization.IMPORT}
                                </Button>
                            </label>
                        </div>
                    </div>
                </div>
                <div className={styles.export}>
                    <Button
                        variant={'contained'}
                        onClick={async () => {
                            const doc = <PDFGeneration incidents={incidents} />;
                            const asPdf = pdf(<></>);
                            asPdf.updateContainer(doc);
                            const blob = await asPdf.toBlob();
                            saveAs(blob, 'SafeX.pdf');
                        }}
                    >
                        {Localization.DOWNLOAD_PDF}
                    </Button>
                </div>
                <div className={styles.cases}>
                    {incidents.map((element) => (
                        <PreviewCase
                            key={element.id}
                            incident={element}
                            className={styles.PreviewCase}
                            onClick={(): void => {
                                dispatch(setSelectedCase(element));
                            }}
                        />
                    ))}
                </div>
            </MainContent>
            <SideMenu></SideMenu>
        </div>
    );
};
