import { Typography } from '@equinor/eds-core-react';
import cx from 'classnames';
import CSS from 'csstype';
import React from 'react';
import pkg from '../../../package.json';
import { TeamsLink } from '../../element/TeamsLink';
import { Localization } from '../../localization/localization';
import styles from './MetaInfo.module.css';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

export const MetaInfo = (props: IProps): JSX.Element => {
    return (
        <div className={cx(styles.MetaInfo, props.className)} style={props.style}>
            <div className={styles.content}>
                <TeamsLink text={Localization.CONTACT_SAFEX_TEAM}></TeamsLink>
            </div>
            <div className={styles.content}>
                <Typography variant="body_short">
                    {Localization.VERSION}: {pkg.version}
                </Typography>
            </div>
        </div>
    );
};
