import { Incident } from '@sdl/sdl-api-client';
import { ActionType } from '../actions';
import { ISearchParameters, ISearchResponse } from './pageState';

export interface ResetPage {
    type: ActionType.ResetPage;
}

export const resetPage = (): ResetPage => {
    return {
        type: ActionType.ResetPage
    };
};

export interface SearchParameters {
    type: ActionType.SearchParameters;
    payload: {
        value: ISearchParameters;
    };
}

export const searchParameters = (value: ISearchParameters): SearchParameters => {
    return {
        type: ActionType.SearchParameters,
        payload: {
            value: value
        }
    };
};

export interface SelectedCase {
    type: ActionType.SelectedCase;
    payload: {
        value: Incident | null;
    };
}

export const setSelectedCase = (value: Incident | null): SelectedCase => {
    return {
        type: ActionType.SelectedCase,
        payload: {
            value: value
        }
    };
};

export interface SearchResponse {
    type: ActionType.SearchResponse;
    payload: {
        value: ISearchResponse;
    };
}

export const searchResponse = (value: ISearchResponse): SearchResponse => {
    return {
        type: ActionType.SearchResponse,
        payload: {
            value: value
        }
    };
};

export interface Searching {
    type: ActionType.Searching;
    payload: {
        value: boolean;
    };
}

export const searching = (value: boolean): Searching => {
    return {
        type: ActionType.Searching,
        payload: {
            value: value
        }
    };
};

export interface HideFilters {
    type: ActionType.HideFilters;
    payload: {
        value: boolean;
    };
}

export const hideFilters = (value: boolean): HideFilters => {
    return {
        type: ActionType.HideFilters,
        payload: {
            value: value
        }
    };
};
