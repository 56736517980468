import { Checkbox } from '@equinor/eds-core-react';
import { DatePicker } from '@equinor/eds-lab-react';
import cx from 'classnames';
import CSS from 'csstype';
import React, { useContext } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FilterBox } from '../../element/filter/FilterBox';
import { useSearch } from '../../hook/useSearch';
import { Localization } from '../../localization/localization';
import { GlobalContext } from '../../state/context';
import { searchParameters } from '../../state/page/pageActions';
import { initialPageState } from '../../state/page/pageState';
import styles from './DateFilters.module.css';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

export const DateFilters = (props: IProps): JSX.Element => {
    const { dispatch, state } = useContext(GlobalContext);
    const searchHook = useSearch();

    return (
        <div className={cx(styles.DateFilters, props.className)} style={props.style}>
            <FilterBox
                title={Localization.DATE}
                onDelete={() => {
                    const params = {
                        ...state.searchParameters,
                        searchDateFrom: initialPageState.searchParameters.searchDateFrom,
                        searchDateTo: initialPageState.searchParameters.searchDateTo,
                        sortByDate: initialPageState.searchParameters.sortByDate
                    };
                    dispatch(searchParameters(params));
                    searchHook.search(params);
                }}
            >
                <DatePicker
                    id={'fromDatePicker'}
                    onChanged={(newDate) => {
                        if (newDate && newDate != state.searchParameters.searchDateFrom) {
                            const params = { ...state.searchParameters, searchDateFrom: newDate };
                            dispatch(searchParameters(params));
                            searchHook.search(params);
                        }
                    }}
                    dateValue={state.searchParameters.searchDateFrom}
                    label={Localization.FROM}
                    disableFuture={true}
                    className={styles.field}
                    dateFormat="dd/MM/yyyy"
                    placeholder="DD/MM/YYYY"
                ></DatePicker>
                <DatePicker
                    id={'toDatePicker'}
                    onChanged={(newDate) => {
                        if (newDate && newDate != state.searchParameters.searchDateTo) {
                            const params = { ...state.searchParameters, searchDateTo: newDate };
                            dispatch(searchParameters(params));
                            searchHook.search(params);
                        }
                    }}
                    dateValue={state.searchParameters.searchDateTo}
                    label={Localization.TO}
                    disableFuture={true}
                    className={styles.field}
                    dateFormat="dd/MM/yyyy"
                    placeholder="DD/MM/YYYY"
                ></DatePicker>

                <Checkbox
                    label={Localization.SORT_BY_DATE}
                    onChange={(e) => {
                        const params = { ...state.searchParameters, sortByDate: e.target.checked };
                        dispatch(searchParameters(params));
                        searchHook.search(params);
                    }}
                    checked={state.searchParameters.sortByDate}
                />
            </FilterBox>
        </div>
    );
};
