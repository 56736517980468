import { FacetResult, Incident } from '@sdl/sdl-api-client';

export interface ISearchParameters {
    searchString: string;
    respUnitFilter: string[];
    workProcessFilter: string[];
    activityFilter: string[];
    countryFilter: string[];
    causeFilter: string[];
    shoreFilter: string[];
    installationFilter: string[];
    severityFilter: string[];
    sourceFilter: string[];
    searchDateFrom: Date;
    searchDateTo: Date;
    sortByDate: boolean;
}

export interface ISearchResponse {
    incidents: Incident[];
    respUnitFacets: FacetResult[];
    workProcessFacets: FacetResult[];
    activityFacets: FacetResult[];
    countryFacets: FacetResult[];
    causeFacets: FacetResult[];
    installationFacets: FacetResult[];
    shoreFacets: FacetResult[];
    severityFacets: FacetResult[];
    sourceFacets: FacetResult[];
}

export interface IPageState {
    searchParameters: ISearchParameters;
    selectedCase: Incident | null;
    searchResponse: ISearchResponse;
    searching: boolean;
    hideFilters: boolean;
}

export const initialPageState = {
    searchParameters: {
        searchString: '',
        respUnitFilter: [] as string[],
        workProcessFilter: [] as string[],
        activityFilter: [] as string[],
        countryFilter: [] as string[],
        causeFilter: [] as string[],
        shoreFilter: [] as string[],
        installationFilter: [] as string[],
        severityFilter: [] as string[],
        sourceFilter: [] as string[],
        searchDateFrom: new Date('2007-07-07'),
        searchDateTo: new Date(),
        sortByDate: true
    },
    selectedCase: null,
    searchResponse: {
        incidents: [],
        respUnitFacets: [],
        workProcessFacets: [],
        activityFacets: [],
        countryFacets: [],
        causeFacets: [],
        installationFacets: [],
        shoreFacets: [],
        severityFacets: [],
        sourceFacets: []
    },
    searching: false,
    hideFilters: false
};
