import { FacetResult } from '@sdl/sdl-api-client';
import CSS from 'csstype';
import _ from 'lodash';
import React, { useContext } from 'react';
import { ISelectFieldOptions, SelectField } from '../../element/input/SelectField';
import { useSearch } from '../../hook/useSearch';
import { Localization } from '../../localization/localization';
import { GlobalContext } from '../../state/context';
import { searchParameters } from '../../state/page/pageActions';
import { ISearchParameters, ISearchResponse } from '../../state/page/pageState';
import styles from './StandardFilter.module.css';

type KeysWithValuesOfType<T, V> = keyof { [P in keyof T as T[P] extends V ? P : never]: P };

export interface IProps {
    style?: CSS.Properties;
    className?: string;
    label: string;
    filter: KeysWithValuesOfType<ISearchParameters, string[]>;
    facet: KeysWithValuesOfType<ISearchResponse, FacetResult[]>;
}

const createOptions = (facetResult: FacetResult[]): ISelectFieldOptions[] => {
    return facetResult.map((x) => {
        return {
            label: `${x.value} (${x.count})`,
            value: x.value
        };
    });
};

export const StandardFilter = (props: IProps): JSX.Element => {
    const { state, dispatch } = useContext(GlobalContext);
    const searchHook = useSearch();

    const onChange = (value: ISelectFieldOptions | ISelectFieldOptions[] | null): void => {
        const asArray = (value as ISelectFieldOptions[]).map((x) => x.value);
        if (!_.isEqual(asArray, state.searchParameters[props.filter])) {
            const params = { ...state.searchParameters };
            params[props.filter] = asArray;
            dispatch(searchParameters(params));
            searchHook.search(params);
        }
    };

    return (
        <SelectField
            label={props.label}
            placeholder={Localization.SELECTION}
            isMulti={true}
            className={styles.StandardFilter}
            selectedOptions={state.searchParameters[props.filter].map((x) => {
                return { label: x, value: x };
            })}
            options={createOptions(state.searchResponse[props.facet])}
            onRemovedItem={onChange}
            onBlur={onChange}
            closeMenuOnSelect={false}
        ></SelectField>
    );
};
