import { Facet, FacetResult, SearchRequest, SearchResponse } from '@sdl/sdl-api-client';
import _ from 'lodash';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { Api } from '../api/Api';
import { EFacet, getFacetKey } from '../config/dataConfig';
import { appInsights } from '../services/log/AppInsights';
import { GlobalContext } from '../state/context';
import { hideFilters, searching, searchResponse } from '../state/page/pageActions';
import { initialPageState, ISearchParameters, ISearchResponse } from '../state/page/pageState';
import {
    getSearchRequestFromSearchParameters,
    getUrlFromSearchParameters
} from '../utils/filter/searchParametersUtils';
import { toastify } from '../utils/toastify/toastifyUtils';

export const useSearch = (): { search: (searchParameters: ISearchParameters) => void } => {
    const { state, dispatch } = useContext(GlobalContext);
    const navigate = useNavigate();

    async function search(searchParameters: ISearchParameters) {
        const searchRequest = getSearchRequestFromSearchParameters(searchParameters, state.language);

        dispatch(searching(true));

        try {
            const response = await fetchSearchResult(searchRequest);
            dispatch(searchResponse(response));
            dispatch(hideFilters(false));

            navigate(getUrlFromSearchParameters(searchParameters, state.language));

            dispatch(searching(false));
        } catch (er) {
            dispatch(searching(false));
            appInsights.trackException({ exception: er as Error });
            toastify('error', '^Unable to load cases');
        }
    }

    async function fetchSearchResult(searchRequest: SearchRequest): Promise<ISearchResponse> {
        const api = new Api();

        const mainPromise = api.search(searchRequest);
        const countryPromise = fetchFilterPromise(searchRequest, api, EFacet.COUNTRY);
        const installationPromise = fetchFilterPromise(searchRequest, api, EFacet.INSTALLATION);
        const shorePromise = fetchFilterPromise(searchRequest, api, EFacet.SHORE);
        const respUnitPromise = fetchFilterPromise(searchRequest, api, EFacet.RESPONSIBLE_UNIT);
        const workPromise = fetchFilterPromise(searchRequest, api, EFacet.WORK_PROCESS);
        const activityPromise = fetchFilterPromise(searchRequest, api, EFacet.ACTIVITY);
        const sourcePromise = fetchFilterPromise(searchRequest, api, EFacet.SOURCE);

        const mainResp = await mainPromise;
        const countryResp = await countryPromise;
        const installationResp = await installationPromise;
        const shoreResp = await shorePromise;
        const respUnitResp = await respUnitPromise;
        const workResp = await workPromise;
        const activityResp = await activityPromise;
        const sourceResp = await sourcePromise;

        const resp = { ...initialPageState.searchResponse, incidents: mainResp.incidents } as ISearchResponse;
        if (mainResp.facets) {
            resp.countryFacets = getFacetResult(countryResp?.facets ?? mainResp.facets, EFacet.COUNTRY);
            resp.installationFacets = getFacetResult(installationResp?.facets ?? mainResp.facets, EFacet.INSTALLATION);
            resp.shoreFacets = getFacetResult(shoreResp?.facets ?? mainResp.facets, EFacet.SHORE);
            resp.respUnitFacets = getFacetResult(respUnitResp?.facets ?? mainResp.facets, EFacet.RESPONSIBLE_UNIT);
            resp.workProcessFacets = getFacetResult(workResp?.facets ?? mainResp.facets, EFacet.WORK_PROCESS);
            resp.activityFacets = getFacetResult(activityResp?.facets ?? mainResp.facets, EFacet.ACTIVITY);
            resp.sourceFacets = getFacetResult(sourceResp?.facets ?? mainResp.facets, EFacet.SOURCE);
            resp.severityFacets = getFacetResult(mainResp.facets, EFacet.SEVERITY);
            resp.causeFacets = getFacetResult(mainResp.facets, EFacet.CAUSE);
        }
        return resp;
    }

    const getFacetResult = (facets: Facet[], key: EFacet): FacetResult[] => {
        const facet = _.filter(facets, (facet) => {
            return facet.fieldName === getFacetKey(key, state.language);
        })[0];
        return facet.facetResults;
    };

    const hasFilter = (searchRequest: SearchRequest, facetName: EFacet): boolean => {
        return !!searchRequest.filters?.map((x) => x.fieldName).includes(getFacetKey(facetName, state.language));
    };

    const fetchFilterPromise = (
        searchRequest: SearchRequest,
        api: Api,
        facetName: EFacet
    ): Promise<SearchResponse> | null => {
        if (hasFilter(searchRequest, facetName)) {
            const request = {
                ...searchRequest,
                top: 0,
                filters: searchRequest.filters?.filter((x) => x.fieldName != getFacetKey(facetName, state.language))
            };
            return api.search(request);
        } else {
            return null;
        }
    };

    return { search };
};
