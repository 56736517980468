import cx from 'classnames';
import CSS from 'csstype';
import React from 'react';
import styles from './SidePanel.module.css';

export enum ESidePanelSize {
    FULL,
    SMALL
}

export enum ESidePanelSide {
    LEFT,
    RIGHT
}

export interface IProps {
    size: ESidePanelSize;
    side: ESidePanelSide;
    children?: React.ReactNode | React.ReactNode[];
    style?: CSS.Properties;
    className?: string;
}

export const SidePanel = (props: IProps): JSX.Element => {
    return (
        <div
            className={cx(
                styles.SidePanel,
                props.className,
                styles[ESidePanelSide[props.side]],
                styles[ESidePanelSize[props.size]]
            )}
            style={props.style}
        >
            {props.children}
        </div>
    );
};
