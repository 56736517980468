import { Slider } from '@equinor/eds-core-react';
import cx from 'classnames';
import CSS from 'csstype';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { InputLabel } from '../../element/input/InputLabel';
import { useSearch } from '../../hook/useSearch';
import { Localization } from '../../localization/localization';
import { GlobalContext } from '../../state/context';
import { searchParameters } from '../../state/page/pageActions';
import styles from './SeverityFilter.module.css';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

export const SeverityFilter = (props: IProps): JSX.Element => {
    const { state, dispatch } = useContext(GlobalContext);
    const [selectedSeverity, setSelectedSeverity] = useState<number[]>([1, 5]);
    const [severityCount, setSeverityCount] = useState<number>(0);
    const searchHook = useSearch();

    useEffect(() => {
        const selected = state.searchResponse.severityFacets.map((x) => parseInt(x.value));
        setSelectedSeverity([_.min(selected) ?? 1, _.max(selected) ?? 5]);

        setSeverityCount(_.sum(state.searchResponse.severityFacets.map((x) => x.count)));
    }, [state.searchResponse.severityFacets]);

    return (
        <div className={cx(styles.SeverityFilter, props.className)} style={props.style}>
            <InputLabel text={`${Localization.SEVERITY} (${severityCount})`}></InputLabel>

            <Slider
                aria-labelledby=""
                min={1}
                max={5}
                minMaxDots
                minMaxValues
                onChangeCommitted={(e, values) => {
                    const [minSelected, maxSelected] = [_.min(values as number[]) ?? 1, _.max(values as number[]) ?? 5];
                    const selected = _.range(minSelected, maxSelected + 1).map((x) => x.toString());
                    const params = { ...state.searchParameters, severityFilter: selected };
                    dispatch(searchParameters(params));
                    searchHook.search(params);
                }}
                step={1}
                value={selectedSeverity}
            />
        </div>
    );
};
