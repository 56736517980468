import { Typography } from '@equinor/eds-core-react';
import cx from 'classnames';
import CSS from 'csstype';
import React, { useContext } from 'react';
import { useSearch } from '../../hook/useSearch';
import { Localization } from '../../localization/localization';
import { GlobalContext } from '../../state/context';
import { hideFilters, searchParameters } from '../../state/page/pageActions';
import { initialPageState } from '../../state/page/pageState';
import styles from './RiskMoments.module.css';

const risks = [Localization.RISK1, Localization.RISK2, Localization.RISK3, Localization.RISK4, Localization.RISK5];
export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

export const RiskMoments = (props: IProps): JSX.Element => {
    const { dispatch } = useContext(GlobalContext);
    const searchHook = useSearch();

    const onRiskMomentClick = (item: string) => {
        dispatch(hideFilters(true));
        const params = { ...initialPageState.searchParameters, searchString: item };
        dispatch(searchParameters(params));
        searchHook.search(params);
    };

    return (
        <div className={cx(styles.RiskMoments, props.className)} style={props.style}>
            <Typography variant="h5" className={styles.title}>
                {Localization.RISK_MOMENTS}
            </Typography>
            <ul>
                {risks.map((item, index) => (
                    <li key={index}>
                        <span
                            onClick={() => onRiskMomentClick(item)}
                            tabIndex={0}
                            onKeyDown={(event): void => {
                                if (event.key == 'Enter') {
                                    onRiskMomentClick(item);
                                }
                            }}
                        >
                            {item}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
};
