import { Button, Icon } from '@equinor/eds-core-react';
import cx from 'classnames';
import CSS from 'csstype';
import React, { useContext, useState } from 'react';
import { useSearch } from '../../hook/useSearch';
import { Localization } from '../../localization/localization';
import { GlobalContext } from '../../state/context';
import { searchParameters } from '../../state/page/pageActions';
import { initialPageState } from '../../state/page/pageState';
import { determineLayoutVisibilityTypeHidden } from '../../utils/display/displayUtils';
import { Filters } from './Filters';
import styles from './FilterWrapper.module.css';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

export const FilterWrapper = (props: IProps): JSX.Element => {
    const { state, dispatch } = useContext(GlobalContext);
    const searchHook = useSearch();
    const [showFilters, setShowFilters] = useState<boolean>(determineLayoutVisibilityTypeHidden('safex_showfilters'));

    return (
        <div className={cx(styles.FilterWrapper, props.className)} style={props.style}>
            <div className={styles.filterBlock}>
                {showFilters && (
                    <Button
                        className={styles.button}
                        onClick={(): void => {
                            setShowFilters(false);
                            localStorage.setItem('safex_showfilters', 'false');
                        }}
                    >
                        <Icon name="filter_alt" title={'Primary'}></Icon>
                        {Localization.HIDE_FILTERS}
                    </Button>
                )}
                {!showFilters && (
                    <Button
                        className={styles.button}
                        onClick={(): void => {
                            setShowFilters(true);
                            localStorage.setItem('safex_showfilters', 'true');
                        }}
                    >
                        <Icon name="filter_alt" title={'Primary'}></Icon>
                        {Localization.SHOW_FILTERS}
                    </Button>
                )}
                <Button
                    className={cx(styles.button, styles.filters)}
                    onClick={(): void => {
                        const params = {
                            ...initialPageState.searchParameters,
                            searchString: state.searchParameters.searchString
                        };
                        dispatch(searchParameters(params));
                        searchHook.search(params);
                    }}
                >
                    <Icon name={'delete_to_trash'} title={'Primary'}></Icon>
                    {Localization.DELETE_FILTERS}
                </Button>
                {showFilters && <Filters className={styles.filters}></Filters>}
            </div>
        </div>
    );
};
