import { Icon } from '@equinor/eds-core-react';
import {
    business,
    cloud,
    delete_to_trash,
    filter_alt,
    flame,
    help_outline,
    history,
    hospital,
    info_circle,
    language,
    link,
    list,
    pan_tool,
    platform,
    power_button,
    save,
    scatter_plot,
    star_outlined,
    view_module,
    warning_outlined,
    waves
} from '@equinor/eds-icons';
import cx from 'classnames';
import CSS from 'csstype';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { DesignPage } from './pages/DesignPage';
import { FAQPage } from './pages/FAQPage';
import { FavoritesPage } from './pages/FavoritesPage';
import { HomePage } from './pages/HomePage';
import { LandingPage } from './pages/LandingPage';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

export const AppContent = (props: IProps): JSX.Element => {
    Icon.add({
        save,
        delete_to_trash,
        pan_tool,
        platform,
        business,
        hospital,
        power_button,
        flame,
        filter_alt,
        cloud,
        star_outlined,
        help_outline,
        link,
        language,
        info_circle,
        list,
        view_module,
        waves,
        warning_outlined,
        scatter_plot,
        history
    });

    return (
        <div className={cx(props.className)} style={props.style}>
            <Router>
                <Routes>
                    <Route path="/favorites" element={<FavoritesPage />} />
                    <Route path="/design" element={<DesignPage />} />
                    <Route path="/faq" element={<FAQPage />} />
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/search" element={<HomePage />} />
                </Routes>
                <ToastContainer />
            </Router>
        </div>
    );
};
