import { TextIconButton } from '@equinor/echo-components';
import { Button, Icon, List, Typography } from '@equinor/eds-core-react';
import { Incident } from '@sdl/sdl-api-client';
import cx from 'classnames';
import CSS from 'csstype';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { SeverityBadge } from '../../element/cases/SeverityBadge';
import { useSearch } from '../../hook/useSearch';
import { Localization } from '../../localization/localization';
import { GlobalContext } from '../../state/context';
import { hideFilters, searchParameters, setSelectedCase } from '../../state/page/pageActions';
import { initialPageState } from '../../state/page/pageState';
import { addFavorites, isIncidentAFavorite, removeFavorite } from '../../utils/favorite/utilsFavorites';
import { getUrlIncludingCase } from '../../utils/filter/filterUtils';
import { getIncidentOneLanguage } from '../../utils/incident/incidentUtils';
import styles from './FullCase.module.css';

export interface IProps {
    incident: Incident;
    onFavoriteRemoved?: (caseNo: string) => void;
    onFavoriteAdded?: (incident: Incident) => void;

    style?: CSS.Properties;
    className?: string;
}

export const FullCase = (props: IProps): JSX.Element => {
    const { state, dispatch } = useContext(GlobalContext);
    const [favorite, setFavorite] = useState<boolean>();
    const navigate = useNavigate();
    const searchHook = useSearch();

    const [showOrgLanguage, setShowOrgLanguage] = useState<boolean>(
        state.language == 'en' || state.language == 'po' ? false : true
    );

    useEffect(() => {
        setFavorite(isIncidentAFavorite(props.incident?.id));
    }, [props.incident?.id]);

    if (!props.incident) {
        return <></>;
    }

    function isFilled(value: string | undefined | string[]): boolean {
        if (typeof value == 'undefined') {
            return false;
        }
        const asArray = [value].flat();
        if (asArray.length == 0) {
            return false;
        }
        if (
            asArray.every((x) => x === '-- Not selected --' || x === '-- Ikke valgt --' || x === '- Não selecionado -')
        ) {
            return false;
        }
        return true;
    }

    const incident = props.incident;
    const incidentOneLanguage = getIncidentOneLanguage(
        showOrgLanguage ? 'no' : state.language == 'no' ? 'en' : state.language,
        props.incident
    );

    const synergiLink = 'https://synergi.equinor.com/synergix/case/' + incident.caseNo;
    const analyticsLink = 'https://ssuanalytics-dev.azurewebsites.net/' + incident.caseNo;

    function onCloseClick() {
        dispatch(setSelectedCase(null));
        navigate(getUrlIncludingCase(undefined));
    }

    return (
        <div className={cx(styles.FullCase, props.className)} style={props.style}>
            <div className={cx(styles.block, styles.block1)}>
                <div className={styles.head}>
                    <SeverityBadge severity={incident.severity}></SeverityBadge>
                    <div
                        className={styles.icon}
                        onClick={onCloseClick}
                        tabIndex={0}
                        onKeyDown={(event): void => {
                            if (event.key == 'Enter') {
                                onCloseClick();
                            }
                        }}
                    >
                        <Icon name="close" title={Localization.CLOSE}></Icon>
                    </div>
                </div>
                <Typography variant="h4" className={styles.mainTitle}>
                    {incidentOneLanguage.title}
                </Typography>
                <Typography variant="body_long">{incidentOneLanguage.description}</Typography>
            </div>

            <div className={cx(styles.block)}>
                <Typography variant="caption" className={styles.title}>
                    {Localization.ACTIONS}
                </Typography>
                <div className={styles.actions}>
                    <TextIconButton
                        icon={'search'}
                        title={Localization.SEARCH_SIMILAR}
                        onClick={() => {
                            dispatch(hideFilters(true));
                            const params = {
                                ...initialPageState.searchParameters,
                                searchString: incidentOneLanguage.title + ' ' + incidentOneLanguage.description
                            };
                            dispatch(searchParameters(params));
                            searchHook.search(params);
                        }}
                    ></TextIconButton>
                    {!favorite && (
                        <TextIconButton
                            icon={'star_outlined'}
                            title={Localization.SAVE_FAVORITE}
                            onClick={() => {
                                addFavorites([{ id: incident.id }]);
                                setFavorite(true);
                                props.onFavoriteAdded?.(incident);
                            }}
                        ></TextIconButton>
                    )}
                    {favorite && (
                        <TextIconButton
                            icon={'delete_to_trash'}
                            title={Localization.REMOVE_FAVORITE}
                            onClick={() => {
                                removeFavorite(incident.id);
                                setFavorite(false);
                                props.onFavoriteRemoved?.(incident.id);
                            }}
                        ></TextIconButton>
                    )}
                    <TextIconButton
                        icon={'language'}
                        title={
                            showOrgLanguage
                                ? Localization.SHOW_TRANSLATED_LANGUAGE
                                : Localization.SHOW_ORIGINAL_LANGUAGE
                        }
                        onClick={() => {
                            setShowOrgLanguage(!showOrgLanguage);
                        }}
                    ></TextIconButton>
                </div>
            </div>

            <div className={cx(styles.block)}>
                <Typography variant="caption" className={styles.title}>
                    {Localization.EXTERNAL_SOURCES}
                </Typography>
                <div className={styles.links}>
                    <a href={synergiLink} target={'_blank'} rel="noreferrer" tabIndex={-1}>
                        <Button variant={'outlined'}>
                            <Icon name="link" title={'Primary'}></Icon>Synergi
                        </Button>
                    </a>
                    <a href={analyticsLink} target={'_blank'} rel="noreferrer" tabIndex={-1}>
                        <Button variant={'outlined'}>
                            <Icon name="link" title={'Primary'}></Icon>
                            {Localization.ANALYTICS}
                        </Button>
                    </a>
                    {incident.source == 'Siop' && (
                        <a href={incident.url} target={'_blank'} rel="noreferrer" tabIndex={-1}>
                            <Button variant={'outlined'}>
                                <Icon name="link" title={'Primary'}></Icon>SIOP
                            </Button>
                        </a>
                    )}
                </div>
            </div>

            {isFilled(incidentOneLanguage.causeCategory) && (
                <div className={cx(styles.block)}>
                    <Typography variant="caption" className={styles.title}>
                        {Localization.CAUSE_CATEGORY}
                    </Typography>
                    <List>
                        {incidentOneLanguage.causeCategory?.map((x, i) => (
                            <List.Item key={i}>{x}</List.Item>
                        ))}
                    </List>
                </div>
            )}

            {isFilled(incidentOneLanguage.cause) && (
                <div className={cx(styles.block)}>
                    <Typography variant="caption" className={styles.title}>
                        {Localization.CAUSE}
                    </Typography>
                    <List>
                        {incidentOneLanguage.cause?.map((x, i) => (
                            <List.Item key={i}>{x}</List.Item>
                        ))}
                    </List>
                </div>
            )}

            {isFilled(incidentOneLanguage.activity) && (
                <div className={cx(styles.block)}>
                    <Typography variant="caption" className={styles.title}>
                        {Localization.ACTIVITY}
                    </Typography>
                    <Typography variant="body_long">{incidentOneLanguage.activity}</Typography>
                </div>
            )}

            {isFilled(incidentOneLanguage.howRevealed) && (
                <div className={cx(styles.block)}>
                    <Typography variant="caption" className={styles.title}>
                        {Localization.HOW_REVEALED}
                    </Typography>
                    <Typography variant="body_long">{incidentOneLanguage.howRevealed}</Typography>
                </div>
            )}
            {isFilled(incidentOneLanguage.workProcess) && (
                <div className={cx(styles.block)}>
                    <Typography variant="caption" className={styles.title}>
                        {Localization.WORK_PROCESS}
                    </Typography>
                    <Typography variant="body_long">{incidentOneLanguage.workProcess}</Typography>
                </div>
            )}

            {isFilled(incidentOneLanguage.respUnit) && (
                <div className={cx(styles.block)}>
                    <Typography variant="caption" className={styles.title}>
                        {Localization.RESPONSIBLE_UNIT}
                    </Typography>
                    <Typography variant="body_long">{incidentOneLanguage.respUnit}</Typography>
                </div>
            )}

            {isFilled(incidentOneLanguage.keyLesson) && (
                <div className={cx(styles.block)}>
                    <Typography variant="caption" className={styles.title}>
                        {Localization.KEY_LESSON}
                    </Typography>
                    <Typography variant="body_long">{incidentOneLanguage.keyLesson}</Typography>
                </div>
            )}

            {isFilled(incidentOneLanguage.classificationComment) && (
                <div className={cx(styles.block)}>
                    <Typography variant="caption" className={styles.title}>
                        {Localization.CLASSIFICATION_COMMENT}
                    </Typography>
                    <Typography variant="body_long">{incidentOneLanguage.classificationComment}</Typography>
                </div>
            )}

            {isFilled(incidentOneLanguage.location) && (
                <div className={cx(styles.block)}>
                    <Typography variant="caption" className={styles.title}>
                        {Localization.LOCATION}
                    </Typography>
                    <Typography variant="body_long">{incidentOneLanguage.location}</Typography>
                </div>
            )}

            {isFilled(incident.source) && (
                <div className={cx(styles.block)}>
                    <Typography variant="caption" className={styles.title}>
                        {Localization.SOURCE}
                    </Typography>
                    <Typography variant="body_long">{incident.source}</Typography>
                </div>
            )}

            {isFilled(incident.caseNo) && (
                <div className={cx(styles.block)}>
                    <Typography variant="caption" className={styles.title}>
                        {Localization.CASE}
                    </Typography>
                    <Typography variant="body_long">{incident.caseNo}</Typography>
                </div>
            )}
        </div>
    );
};
