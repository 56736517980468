import cx from 'classnames';
import CSS from 'csstype';
import React, { useContext } from 'react';
import { FilterBox } from '../../element/filter/FilterBox';
import { useSearch } from '../../hook/useSearch';
import { Localization } from '../../localization/localization';
import { GlobalContext } from '../../state/context';
import { searchParameters } from '../../state/page/pageActions';
import { initialPageState } from '../../state/page/pageState';
import styles from './LocationFilters.module.css';
import { StandardFilter } from './StandardFilter';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

export const LocationFilters = (props: IProps): JSX.Element => {
    const { state, dispatch } = useContext(GlobalContext);
    const searchHook = useSearch();

    return (
        <div className={cx(styles.LocationFilters, props.className)} style={props.style}>
            <FilterBox
                title={Localization.LOCATION}
                onDelete={() => {
                    const params = {
                        ...state.searchParameters,
                        countryFilter: initialPageState.searchParameters.countryFilter,
                        shoreFilter: initialPageState.searchParameters.shoreFilter,
                        installationFilter: initialPageState.searchParameters.installationFilter
                    };
                    dispatch(searchParameters(params));
                    searchHook.search(params);
                }}
            >
                <StandardFilter
                    label={Localization.COUNTRY}
                    filter="countryFilter"
                    facet="countryFacets"
                ></StandardFilter>
                <StandardFilter label={Localization.SHORE} filter="shoreFilter" facet="shoreFacets"></StandardFilter>
                <StandardFilter
                    label={Localization.INSTALLATION}
                    filter="installationFilter"
                    facet="installationFacets"
                ></StandardFilter>
            </FilterBox>
        </div>
    );
};
