import { Button, Chip, Icon, Typography } from '@equinor/eds-core-react';
import cx from 'classnames';
import CSS from 'csstype';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactWordcloud, { Word } from 'react-wordcloud';
import { useSearch } from '../../hook/useSearch';
import { Localization } from '../../localization/localization';
import { GlobalContext } from '../../state/context';
import { hideFilters, searchParameters } from '../../state/page/pageActions';
import { determineLayoutVisibilityTypeDiffers } from '../../utils/display/displayUtils';
import styles from './SafexWordCloud.module.css';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
    words: Word[];
}

export const SafexWordCloud = (props: IProps): JSX.Element => {
    const { state, dispatch } = useContext(GlobalContext);
    const ref = useRef<HTMLDivElement>(null);
    const [words, setWords] = useState<number>(0);
    const [size, setSize] = useState<number[]>([]);
    const searchHook = useSearch();

    const updateCauseFilter = (causeFilter: string[]) => {
        dispatch(hideFilters(true));
        const params = { ...state.searchParameters, causeFilter: causeFilter };
        dispatch(searchParameters(params));
        searchHook.search(params);
    };

    const callbacks = {
        onWordClick: (word: Word) => updateCauseFilter([...state.searchParameters.causeFilter, word.id])
    };

    const [showCloud, setShowCloud] = useState<boolean>(determineLayoutVisibilityTypeDiffers('safex_showcloud'));

    useEffect(() => {
        if (ref.current) {
            const width = ref.current.clientWidth - 30; //padding
            if (width > 400) {
                setWords(10);
                setSize([10, 28]);
            } else {
                setWords(5);
                setSize([5, 18]);
            }
        }
    }, [ref, showCloud]);

    return (
        <div className={cx(styles.SafexWordCloud, props.className)} style={props.style}>
            <div className={styles.head}>
                <div className={styles.title}>
                    <Typography variant="h5">{Localization.RISK_MOMENTS}</Typography>

                    <Button
                        variant="ghost_icon"
                        color="primary"
                        onClick={(): void => {
                            setShowCloud(!showCloud);
                            localStorage.setItem('safex_showcloud', showCloud ? 'false' : 'true');
                        }}
                    >
                        <Icon name={showCloud ? 'chevron_up' : 'chevron_down'} title={'Primary'}></Icon>
                    </Button>
                </div>
                <div className={styles.chips}>
                    {state.searchParameters.causeFilter.map((cause) => (
                        <Chip
                            className={styles.chip}
                            key={cause}
                            onDelete={(): void => {
                                updateCauseFilter(state.searchParameters.causeFilter.filter((x) => x != cause));
                            }}
                        >
                            {cause.split(' - ').pop()}
                        </Chip>
                    ))}
                </div>
            </div>
            {showCloud && (
                <div className={styles.content} ref={ref}>
                    {words && size && (
                        <ReactWordcloud
                            words={props.words.filter((x) => !state.searchParameters.causeFilter.includes(x.id))}
                            callbacks={callbacks}
                            maxWords={words}
                            options={{
                                colors: ['#1f93cb', '#4e914c', '#b30d2f', '#4f7bad', '#ff9200', '#007079'],
                                enableTooltip: true,
                                deterministic: true,
                                fontFamily: 'equinor',
                                fontSizes: [size[0], size[1]],
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                padding: 5,
                                rotations: 0,
                                rotationAngles: [0, 90],
                                scale: 'sqrt',
                                spiral: 'archimedean',
                                transitionDuration: 1000
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
