import { Typography } from '@equinor/eds-core-react';
import React from 'react';

export interface IProps {
    text: string;
}

export const TeamsLink = (props: IProps): JSX.Element => {
    return (
        <Typography
            link
            href={
                'https://teams.microsoft.com/l/channel/19%3ac42923a39e694ca595b64219d54ad787%40thread.tacv2/General?groupId=a28ff0ef-7d0f-4e9f-9b2f-cbfab594085f&tenantId=3aa4a235-b6e2-48d5-9195-7fcf05b459b0'
            }
            target="_blank"
            rel="noreferrer"
        >
            {props.text}
        </Typography>
    );
};
