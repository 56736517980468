import { TextIconButton } from '@equinor/echo-components';
import { Button, Checkbox, Icon, Search, Slider, Typography } from '@equinor/eds-core-react';
import cx from 'classnames';
import CSS from 'csstype';
import React from 'react';
import { ESeverity, SeverityBadge } from '../element/cases/SeverityBadge';
import { FilterBox } from '../element/filter/FilterBox';
import { InputLabel } from '../element/input/InputLabel';
import { SelectField } from '../element/input/SelectField';
import { FrequentSearches } from '../structure/page/FrequentSearches';
import { MetaInfo } from '../structure/page/MetaInfo';
import { PageFooter } from '../structure/page/PageFooter';
import { PageHeader } from '../structure/page/PageHeader';
import { RiskMoments } from '../structure/page/RiskMoments';
import { SafexWordCloud } from '../structure/page/SafexWordCloud';
import styles from './DesignPage.module.css';
export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

export const DesignPage = (props: IProps): JSX.Element => {
    return (
        <div className={cx(styles.DesignPage, props.className)} style={props.style}>
            {/* EDS  */}
            <div className={styles.category}>
                <div className={styles.variant}>
                    <Button>
                        <Icon name="save" title={'Primary'}></Icon>Primary
                    </Button>
                    <Button variant="outlined">Outlined</Button>
                </div>
                <Typography variant="h5">Frequent searches</Typography>
                <Typography variant="h4">Fire truck did not start by random test</Typography>
                <Typography variant="body_long">
                    Fire truck did not start at random test, as there was poor power on the batt. The car was charging,
                    but it turns out that the internal maintenance charger is defective. Had to use external starting
                    aid (charger) to get the car started.
                </Typography>
                <Typography variant="body_short">Norway · 27.12. 2008</Typography>
                <Typography variant="caption">Cause category</Typography>
                <Checkbox label="Play with me" />
                <div style={{ width: '375px' }}>
                    <Search
                        aria-label="sitewide"
                        placeholder="Search"
                        onChange={() => {
                            console.log('Change');
                        }}
                    />
                </div>
                <div style={{ width: '375px' }}>
                    <Slider
                        aria-labelledby=""
                        max={100}
                        minMaxDots
                        minMaxValues
                        onChange={() => {
                            console.log('Change');
                        }}
                        onChangeCommitted={() => {
                            console.log('Change2');
                        }}
                        step={1}
                        value={[40, 60]}
                    />
                </div>
            </div>

            {/* Echo components */}
            <div className={styles.category}>
                <TextIconButton
                    icon={'cut'}
                    title={'Saved Slices'}
                    onClick={() => {
                        console.log('Click');
                    }}
                ></TextIconButton>
            </div>

            {/* Cases  */}
            <div className={styles.category}>
                <div className={styles.variant}>
                    <SeverityBadge severity={ESeverity.A}></SeverityBadge>
                    <SeverityBadge severity={ESeverity.B}></SeverityBadge>
                    <SeverityBadge severity={ESeverity.C}></SeverityBadge>
                    <SeverityBadge severity={ESeverity.D}></SeverityBadge>
                    <SeverityBadge severity={ESeverity.E}></SeverityBadge>
                </div>
                {/* <PreviewCase
                    severity={ESeverity.C}
                    title="Not able to obtain inflow test on middle completion. "
                    description="Not able to obtain injury test on middle completion.  Displaced well back to 1,35sg KCCOH brine.  Tried to inflow test middle completion with RTTS and sealstem... Not able to obtain injury test on middle completion.  Displaced well back to 1,35sg KCCOH brine.  Tried to inflow test middle completion with RTTS and sealstem..."
                    footer="Norway · 27.12. 2008"
                ></PreviewCase> */}
                {/* <FullCase
                    style={{ width: '375px' }}
                    case={{
                        severity: ESeverity.C,
                        title: 'Fire truck did not start by random test',
                        description:
                            'Fire truck did not start at random test, as there was poor power on the batt. The car was charging, but it turns out that the internal maintenance charger is defective.  Had to use external starting aid (charger) to get the car started.  Fire truck did not start at random test, as there was poor power on the batt. The car was charging, but it turns out that the internal maintenance charger is defective.  Had to use external starting aid (charger) to get the car started. ',
                        source: {
                            synergiLink: 'https://google.com',
                            analyticsLink: 'https://yahoo.com'
                        },
                        category:
                            'Causes related to management and control; Underlying causes; Underlying causes; Underlying causes; Underlying causes; Underlying causes; Underlying causes; Underlying causes; Immediate causes',
                        cause:
                            ' Inadequate management within: - Recruitment and skill upgrading; Organisation and planning of work - Insufficient time allotted to prepare the work; Organisation and planning of work - Insufficient time allotted to do the work; Organisation and planning of work - Inadequate risk assessment prior to the ',
                        activity: 'Work execution - Function testing / pressure testing',
                        revealed: 'Security message',
                        process: 'Operations/production - Testing',
                        unit: 'TPD - PRD - GP1 - GJOA - Subsea & Pipelines'
                    }}
                ></FullCase> */}
            </div>

            {/* Filters */}
            <div className={styles.category}>
                <div className={styles.variant}>
                    <FilterBox title={'Source'}>...</FilterBox>
                </div>
            </div>

            {/* Structure */}
            <div className={styles.category}>
                <PageHeader></PageHeader>
                <div style={{ width: '350px' }}>
                    <FrequentSearches />
                </div>
                <div style={{ width: '350px' }}>
                    <RiskMoments />
                </div>
                <div style={{ width: '350px' }}>
                    <PageFooter className={styles.PageFooter}></PageFooter>
                </div>
            </div>

            {/* Input */}
            <div className={styles.category}>
                <InputLabel text="Responsible unit"></InputLabel>
                <div style={{ width: '350px' }}>
                    <SelectField options={[]} label="Country" placeholder="Selection" isMulti={true}></SelectField>
                </div>
                {/* <DateField initialDate={new Date()} label="Field"></DateField> */}
            </div>

            {/* Filter boxes */}
            <div className={styles.category}>
                {/* <SourceFilters />
                <DateFilters />
                <LocationFilters />
                <OtherFilters /> */}
            </div>

            {/* InfoMeny */}
            <div className={styles.category}>
                <div style={{ width: '350px' }}>
                    <MetaInfo className={styles.MetaInfo}></MetaInfo>
                </div>
            </div>

            {/* WordCloud */}
            <div className={styles.category}>
                <div className={styles.variant}>
                    <SafexWordCloud
                        className={styles.SafexWordCloud}
                        words={[
                            {
                                text: 'told',
                                value: 64
                            },
                            {
                                text: 'mistake',
                                value: 11
                            },
                            {
                                text: 'thought',
                                value: 16
                            },
                            {
                                text: 'bad',
                                value: 17
                            }
                        ]}
                    ></SafexWordCloud>
                </div>
            </div>
        </div>
    );
};
