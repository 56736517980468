import { Localization } from '../../localization/localization';

const localstore_language = localStorage.getItem('safex_language');
let current_language: string;
if (localstore_language) {
    current_language = localstore_language;
    Localization.setLanguage(current_language);
} else {
    current_language = 'en';
    Localization.setLanguage('en');
}

export interface IUserState {
    language: string;
}

export const initialUserState = {
    language: current_language
};
