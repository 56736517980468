import cx from 'classnames';
import CSS from 'csstype';
import React from 'react';
import styles from './MainContent.module.css';

export interface IProps {
    children: React.ReactNode | React.ReactNode[];
    style?: CSS.Properties;
    className?: string;
}

export const MainContent = (props: IProps): JSX.Element => {
    return (
        <div className={cx(styles.MainContent, props.className)} style={props.style}>
            {props.children}
        </div>
    );
};
