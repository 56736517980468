import { Configuration, Incident, SearchApi, SearchRequest, SearchResponse } from '@sdl/sdl-api-client';
import { BearerTokenMiddleware, ErrorPropagationMiddleware } from './middleware';

let apiPath: string;

if (window.location.hostname === 'safex.equinor.com') {
    apiPath = 'https://api-safexapi-prod.radix.equinor.com';
} else if (window.location.hostname === 'gui-safex-prod.radix.equinor.com') {
    apiPath = 'https://api-safexapi-prod.radix.equinor.com';
} else if (window.location.hostname === 'gui-safex-qa.radix.equinor.com') {
    apiPath = 'https://api-safexapi-qa.radix.equinor.com';
} else if (window.location.hostname === 'gui-safex-dev.radix.equinor.com') {
    apiPath = 'https://api-safexapi-dev.radix.equinor.com';
} else if (window.location.hostname === 'gui-safex-radixdev.radix.equinor.com') {
    apiPath = 'https://api-safexapi-radixdev.radix.equinor.com';
} else {
    apiPath = 'http://localhost:5030';
}

export class Api {
    defaultApi: SearchApi;

    constructor() {
        const config = new Configuration({
            basePath: apiPath,
            middleware: [new BearerTokenMiddleware(), new ErrorPropagationMiddleware()]
        });
        this.defaultApi = new SearchApi(config);
    }

    search(requestParameters: SearchRequest): Promise<SearchResponse> {
        if (
            requestParameters.filters?.length === 0 ||
            requestParameters.filters === undefined ||
            requestParameters.filters[0].fieldName === ''
        ) {
            requestParameters.filters = [];
        }

        return this.defaultApi.search({ searchRequest: requestParameters });
    }

    incidentsByIds(ids: string[]): Promise<Incident[]> {
        return this.defaultApi.incidentsByIds({ requestBody: ids });
    }
}
