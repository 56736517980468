import { Icon, Typography } from '@equinor/eds-core-react';
import cx from 'classnames';
import CSS from 'csstype';
import React from 'react';
import styles from './PageFooter.module.css';
import { Localization } from '../../localization/localization';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

export const PageFooter = (props: IProps): JSX.Element => {
    return (
        <div className={cx(styles.PageFooter, props.className)} style={props.style}>
            <div className={styles.content}>
                <Icon name="warning_filled"></Icon>
                <Typography variant="body_short">{Localization.SAFEX_DATA_INTERNAL}</Typography>
            </div>
        </div>
    );
};
