import { DotProgress, Icon } from '@equinor/eds-core-react';
import cx from 'classnames';
import React, { useContext, useState } from 'react';
import { useSearch } from '../hook/useSearch';
import { GlobalContext } from '../state/context';
import { hideFilters, searchParameters } from '../state/page/pageActions';
import { initialPageState } from '../state/page/pageState';
import { SideMenu } from '../structure/common/SideMenu';
import { FrequentSearches } from '../structure/page/FrequentSearches';
import { PageHeader } from '../structure/page/PageHeader';
import { RiskMoments } from '../structure/page/RiskMoments';
import { randomInteger } from '../utils/random/randomUtils';
import styles from './LandingPage.module.css';

export interface IProps {
    className?: string;
}

type QuerySet = {
    image: string;
    query: string;
};

const sets: QuerySet[] = [
    {
        image: 'dudgeon-min.jpg',
        query: 'Dudgeon'
    },
    {
        image: 'aasta_hansteen-min.jpg',
        query: 'Aasta Hansteen'
    },
    {
        image: 'forus-min.jpg',
        query: 'Forus'
    },
    {
        image: 'hywind_tampen-min.jpg',
        query: 'Hywind Tampen'
    },
    {
        image: 'johan_sverdrup-min.jpg',
        query: 'Johan Sverdrup'
    },
    {
        image: 'kollsnes-min.jpg',
        query: 'Kollsnes'
    },
    {
        image: 'martin_linge-min.jpg',
        query: 'Martin Linge'
    },
    {
        image: 'peregrino_b-min.jpg',
        query: 'Peregrino B'
    }
];

export const LandingPage = (props: IProps): JSX.Element => {
    const { state, dispatch } = useContext(GlobalContext);
    const [index] = useState<number>(randomInteger(0, sets.length - 1));
    const searchHook = useSearch();

    function onSetClick(set: QuerySet) {
        dispatch(hideFilters(true));
        const params = { ...initialPageState.searchParameters, searchString: set.query };
        dispatch(searchParameters(params));
        searchHook.search(params);
    }

    return (
        <div
            className={cx(styles.LandingPage, props.className)}
            style={{
                backgroundImage: `url('./${sets[index]?.image}')`
            }}
        >
            {!state.searching && (
                <>
                    <div className={styles.core}>
                        <PageHeader className={styles.PageHeader}></PageHeader>
                        <FrequentSearches className={styles.FrequentSearches} />
                        <RiskMoments className={styles.RiskMoments} />
                    </div>
                    <div
                        className={styles.search}
                        onClick={() => onSetClick(sets[index])}
                        tabIndex={0}
                        onKeyDown={(event): void => {
                            if (event.key == 'Enter') {
                                onSetClick(sets[index]);
                            }
                        }}
                    >
                        <Icon name={'search'}></Icon>
                        <span>{sets[index]?.query}</span>
                    </div>
                </>
            )}
            {state.searching && (
                <div className={styles.core}>
                    <div className={styles.dots}>
                        <DotProgress size={64} color="primary" />
                    </div>
                </div>
            )}
            <SideMenu></SideMenu>
        </div>
    );
};
