import cx from 'classnames';
import CSS from 'csstype';
import React, { useContext } from 'react';
import { FilterBox } from '../../element/filter/FilterBox';
import { useSearch } from '../../hook/useSearch';
import { Localization } from '../../localization/localization';
import { GlobalContext } from '../../state/context';
import { searchParameters } from '../../state/page/pageActions';
import { initialPageState } from '../../state/page/pageState';
import styles from './SourceFilters.module.css';
import { StandardFilter } from './StandardFilter';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

export const SourceFilters = (props: IProps): JSX.Element => {
    const { state, dispatch } = useContext(GlobalContext);
    const searchHook = useSearch();

    return (
        <div className={cx(styles.SourceFilters, props.className)} style={props.style}>
            <FilterBox
                title={Localization.SOURCE}
                onDelete={() => {
                    const params = {
                        ...state.searchParameters,
                        respUnitFilter: initialPageState.searchParameters.respUnitFilter,
                        workProcessFilter: initialPageState.searchParameters.workProcessFilter,
                        activityFilter: initialPageState.searchParameters.activityFilter
                    };
                    dispatch(searchParameters(params));
                    searchHook.search(params);
                }}
            >
                <StandardFilter
                    label={Localization.RESPONSIBLE_UNIT}
                    filter="respUnitFilter"
                    facet="respUnitFacets"
                ></StandardFilter>
                <StandardFilter
                    label={Localization.WORK_PROCESS}
                    filter="workProcessFilter"
                    facet="workProcessFacets"
                ></StandardFilter>
                <StandardFilter
                    label={Localization.ACTIVITY}
                    filter="activityFilter"
                    facet="activityFacets"
                ></StandardFilter>
            </FilterBox>
        </div>
    );
};
