import React, { useReducer } from 'react';
import { AppContent } from './AppContent';
import Auth from './components/auth/Auth';
import ErrorBoundary from './components/ErrorBoundary';
import './global.css';
import { GlobalContext } from './state/context';
import { stateReducer } from './state/reducer';
import { globalState } from './state/state';

const App = (): JSX.Element => {
    const [state, dispatch] = useReducer(stateReducer, globalState);
    return (
        <>
            <GlobalContext.Provider value={{ state, dispatch }}>
                <ErrorBoundary>
                    <Auth>
                        <AppContent />
                    </Auth>
                </ErrorBoundary>
            </GlobalContext.Provider>
        </>
    );
};

export default App;
