import { Incident } from '@sdl/sdl-api-client';

export interface IncidentOneLanguage {
    id: string;
    caseNo: string;
    source: string;
    severity: number;
    title?: string;
    description?: string;
    url?: string;
    location?: string;
    country?: string;
    landOcean?: string;
    installation?: string;
    activity?: string;
    activityLevel1?: string;
    classificationComment?: string;
    respUnit?: string;
    respUnitLevel1?: string;
    workProcess?: string;
    workProcessLevel1?: string;
    howRevealed?: string;
    causeCategory?: string[];
    cause?: string[];
    keyLesson?: string;
    caseDate: Date;
    caseDateString: string;
    searchScore: number;
}

export function getIncidentOneLanguage(language: string, incident: Incident): IncidentOneLanguage {
    const dateOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' } as const;
    const dateLanguage = language == 'en' ? 'en-GB' : language == 'po' ? 'pt-BR' : 'nb-NO';
    return {
        id: incident.id,
        caseNo: incident.caseNo,
        source: incident.source,
        severity: incident.severity,
        title: language == 'en' ? incident.titleEng : language == 'po' ? incident.titlePor : incident.titleOrg,
        description:
            language == 'en'
                ? incident.descriptionEng
                : language == 'po'
                ? incident.descriptionPor
                : incident.descriptionOrg,
        url: incident.url,
        location:
            language == 'en' ? incident.locationEng : language == 'po' ? incident.locationPor : incident.locationOrg,
        country: language == 'en' ? incident.countryEng : language == 'po' ? incident.countryPor : incident.countryOrg,
        landOcean:
            language == 'en' ? incident.landOceanEng : language == 'po' ? incident.landOceanPor : incident.landOceanOrg,
        installation:
            language == 'en'
                ? incident.installationEng
                : language == 'po'
                ? incident.installationPor
                : incident.installationOrg,
        activity:
            language == 'en' ? incident.activityEng : language == 'po' ? incident.activityPor : incident.activityOrg,
        activityLevel1:
            language == 'en'
                ? incident.activityLevel1Eng
                : language == 'po'
                ? incident.activityLevel1Por
                : incident.activityLevel1Org,
        classificationComment:
            language == 'en'
                ? incident.classificationCommentEng
                : language == 'po'
                ? incident.classificationCommentPor
                : incident.classificationCommentOrg,
        respUnit: incident.respUnitOrg,
        respUnitLevel1: incident.respUnitLevel1Org,
        workProcess:
            language == 'en'
                ? incident.workProcessEng
                : language == 'po'
                ? incident.workProcessPor
                : incident.workProcessOrg,
        workProcessLevel1:
            language == 'en'
                ? incident.workProcessLevel1Eng
                : language == 'po'
                ? incident.workProcessLevel1Por
                : incident.workProcessLevel1Org,
        howRevealed:
            language == 'en'
                ? incident.howRevealedEng
                : language == 'po'
                ? incident.howRevealedPor
                : incident.howRevealedOrg,
        causeCategory:
            language == 'en'
                ? incident.causeCategoryEng
                : language == 'po'
                ? incident.causeCategoryPor
                : incident.causeCategoryOrg,
        cause: language == 'en' ? incident.causeEng : language == 'po' ? incident.causePor : incident.causeOrg,
        keyLesson:
            language == 'en' ? incident.keyLessonEng : language == 'po' ? incident.keyLessonPor : incident.keyLessonOrg,
        caseDate: incident.caseDate,
        caseDateString: new Date(incident.caseDate).toLocaleDateString(dateLanguage, dateOptions),
        searchScore: incident.searchScore
    };
}
