import { Checkbox } from '@equinor/eds-core-react';
import cx from 'classnames';
import CSS from 'csstype';
import React, { useContext } from 'react';
import { InputLabel } from '../../element/input/InputLabel';
import { useSearch } from '../../hook/useSearch';
import { Localization } from '../../localization/localization';
import { GlobalContext } from '../../state/context';
import { searchParameters } from '../../state/page/pageActions';
import styles from './SourceFilter.module.css';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

const sourceAlternatives = ['Synergi', 'SIOP'];

export const SourceFilter = (props: IProps): JSX.Element => {
    const { state, dispatch } = useContext(GlobalContext);
    const searchHook = useSearch();

    return (
        <div className={cx(styles.SourceFilter, props.className)} style={props.style}>
            <InputLabel text={Localization.SOURCE}></InputLabel>
            {sourceAlternatives.map((sourceAlternative) => {
                return (
                    <Checkbox
                        key={sourceAlternative}
                        checked={
                            state.searchParameters.sourceFilter.length == 0 ||
                            state.searchParameters.sourceFilter.includes(sourceAlternative)
                        }
                        label={`${sourceAlternative} (${
                            state.searchResponse.sourceFacets
                                .filter((x) => x.value == sourceAlternative)
                                .map((x) => x.count)
                                .find(() => true) ?? 0
                        })`}
                        onChange={(e) => {
                            let newFilter;
                            if (e.target.checked) {
                                newFilter = [...state.searchParameters.sourceFilter, sourceAlternative];
                            } else if (state.searchParameters.sourceFilter.length) {
                                newFilter = state.searchParameters.sourceFilter.filter((x) => x != sourceAlternative);
                            } else {
                                newFilter = sourceAlternatives.filter((x) => x != sourceAlternative);
                            }
                            const params = { ...state.searchParameters, sourceFilter: newFilter };
                            dispatch(searchParameters(params));
                            searchHook.search(params);
                        }}
                    />
                );
            })}
        </div>
    );
};
