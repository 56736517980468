import { Incident } from '@sdl/sdl-api-client';
import { Api } from '../../api/Api';

interface IFavorite {
    id: string;
}

const getFavorites = (): IFavorite[] => {
    const cookie = localStorage.getItem('safex_favorites') ?? '[]';
    return JSON.parse(cookie);
};

const setFavorites = (favorites: IFavorite[]): void => {
    localStorage.setItem('safex_favorites', JSON.stringify(favorites));
};

export const addFavorites = (newFavorites: IFavorite[]): void => {
    const oldFavorites = getFavorites();
    const favoritesToBeAdded = newFavorites.filter((x) => !oldFavorites.map((y) => y.id).includes(x.id));
    setFavorites([...oldFavorites, ...favoritesToBeAdded]);
};

export const isIncidentAFavorite = (id: string): boolean => {
    const favorites = getFavorites();
    return favorites.filter((x) => x.id == id).length > 0;
};

export const getFavoriteIncidents = async (): Promise<Incident[]> => {
    const favorites = getFavorites();
    if (favorites.length == 0) {
        return Promise.resolve([]);
    }
    const api = new Api();
    return api.incidentsByIds(favorites.map((x) => x.id));
};

export const removeFavorite = (id: string): void => {
    const favorites = getFavorites();
    setFavorites(favorites.filter((x) => x.id != id));
};
