import { DotProgress } from '@equinor/eds-core-react';
import cx from 'classnames';
import CSS from 'csstype';
import * as _ from 'lodash';
import qs from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSearch } from '../hook/useSearch';
import { GlobalContext } from '../state/context';
import { hideFilters, searchParameters, setSelectedCase } from '../state/page/pageActions';
import { Cases } from '../structure/cases/Cases';
import { FullCase } from '../structure/cases/FullCase';
import { CloudWrapper } from '../structure/cloud/CloudWrapper';
import { MainContent } from '../structure/common/MainContent';
import { SideMenu } from '../structure/common/SideMenu';
import { ESidePanelSide, ESidePanelSize, SidePanel } from '../structure/common/SidePanel';
import { FilterWrapper } from '../structure/filter/FilterWrapper';
import { FrequentSearches } from '../structure/page/FrequentSearches';
import { PageFooter } from '../structure/page/PageFooter';
import { PageHeader } from '../structure/page/PageHeader';
import { RiskMoments } from '../structure/page/RiskMoments';
import { getUrlIncludingCase } from '../utils/filter/filterUtils';
import { getSearchParametersFromUrl } from '../utils/filter/searchParametersUtils';
import styles from './HomePage.module.css';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

export const HomePage = (props: IProps): JSX.Element => {
    const { state, dispatch } = useContext(GlobalContext);
    const location = useLocation();
    const [caseInit, setCaseInit] = useState<boolean>(false);
    const navigate = useNavigate();
    const searchHook = useSearch();

    useEffect(() => {
        dispatch(hideFilters(true));
        const searchParams = getSearchParametersFromUrl(location.search);
        dispatch(searchParameters(searchParams));
        searchHook.search(searchParams);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!caseInit && state.searchResponse?.incidents?.length > 0 && !state.searching) {
            const query = qs.parse(window.location.search);
            const foundCase = _.find(state.searchResponse.incidents, (incident) => {
                return incident.id === query['case'];
            });
            if (foundCase) {
                dispatch(setSelectedCase(foundCase));
                setCaseInit(true);
                navigate(getUrlIncludingCase(foundCase.id));
            }
        }
    }, [caseInit, dispatch, navigate, state.searching, state.searchResponse.incidents, state]);

    return (
        <div className={cx(styles.HomePage, props.className)} style={props.style}>
            <SidePanel size={ESidePanelSize.FULL} side={ESidePanelSide.LEFT}>
                <PageHeader className={styles.PageHeader}></PageHeader>
                {state.selectedCase && <FullCase incident={state.selectedCase}></FullCase>}
                {!state.selectedCase && (
                    <>
                        <FrequentSearches className={styles.FrequentSearches} />
                        <RiskMoments className={styles.RiskMoments} />
                    </>
                )}
                <PageFooter className={styles.PageFooter}></PageFooter>
            </SidePanel>
            <MainContent className={styles.MainContent}>
                {state.searchResponse.incidents && !state.hideFilters && <FilterWrapper></FilterWrapper>}
                {state.searching && (
                    <div style={{ textAlign: 'center', marginTop: 100 }}>
                        <DotProgress size={64} color="primary" />
                    </div>
                )}
                {state.searchResponse.incidents && !state.searching && (
                    <>
                        <CloudWrapper></CloudWrapper>
                        <div className={styles.cases}>
                            <Cases></Cases>
                        </div>
                    </>
                )}
            </MainContent>
            <SideMenu></SideMenu>
        </div>
    );
};
