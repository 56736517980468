import { Accordion, Typography } from '@equinor/eds-core-react';
import cx from 'classnames';
import CSS from 'csstype';
import * as _ from 'lodash';
import React from 'react';
import { Localization } from '../localization/localization';
import { MainContent } from '../structure/common/MainContent';
import { SideMenu } from '../structure/common/SideMenu';
import { ESidePanelSide, ESidePanelSize, SidePanel } from '../structure/common/SidePanel';
import { FrequentSearches } from '../structure/page/FrequentSearches';
import { PageFooter } from '../structure/page/PageFooter';
import { PageHeader } from '../structure/page/PageHeader';
import { RiskMoments } from '../structure/page/RiskMoments';
import styles from './FAQPage.module.css';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

export const FAQPage = (props: IProps): JSX.Element => {
    return (
        <div className={cx(styles.FAQPage, props.className)} style={props.style}>
            <SidePanel size={ESidePanelSize.FULL} side={ESidePanelSide.LEFT}>
                <PageHeader className={styles.PageHeader}></PageHeader>
                <FrequentSearches className={styles.FrequentSearches} />
                <RiskMoments className={styles.RiskMoments} />
                <PageFooter className={styles.PageFooter}></PageFooter>
            </SidePanel>
            <MainContent className={styles.MainContent}>
                <div className={styles.questions}>
                    <Accordion>
                        <Accordion.Item isExpanded>
                            <Accordion.Header>{Localization.FAQ_WHAT_IS_SAFEX_QUESTION}</Accordion.Header>
                            <Accordion.Panel>{Localization.FAQ_WHAT_IS_SAFEX_ANSWER}</Accordion.Panel>
                        </Accordion.Item>
                        <Accordion.Item>
                            <Accordion.Header>{Localization.HOW_TO_SEARCH}</Accordion.Header>
                            <Accordion.Panel>
                                <div className={styles.answer}>
                                    <Typography variant="body_short_bold">{Localization.SIMPLE_SEARCH}</Typography>
                                    <Typography variant="body_long">{Localization.SEARCH_TIP_1}</Typography>
                                    <Typography variant="body_short" color="primary">
                                        {Localization.SEARCH_EXAMPLE_1}
                                    </Typography>
                                </div>
                                <div className={styles.answer}>
                                    <Typography variant="body_short_bold">+ {Localization.OPERATOR}</Typography>
                                    <Typography variant="body_long">{Localization.SEARCH_TIP_2}</Typography>
                                    <Typography variant="body_short" color="primary">
                                        {Localization.SEARCH_EXAMPLE_2}
                                    </Typography>
                                </div>
                                <div className={styles.answer}>
                                    <Typography variant="body_short_bold">- {Localization.OPERATOR}</Typography>
                                    <Typography variant="body_long">{Localization.SEARCH_TIP_3}</Typography>
                                    <Typography variant="body_short" color="primary">
                                        {Localization.SEARCH_EXAMPLE_3}
                                    </Typography>
                                </div>
                                <div className={styles.answer}>
                                    <Typography variant="body_short_bold">
                                        &quot; &quot; {Localization.QUOTES}
                                    </Typography>
                                    <Typography variant="body_long">{Localization.SEARCH_TIP_4}</Typography>
                                    <Typography variant="body_short" color="primary">
                                        {_.unescape(Localization.SEARCH_EXAMPLE_4)}
                                    </Typography>
                                </div>
                                <div className={styles.answer}>
                                    <Typography variant="body_short_bold">* {Localization.OPERATOR}</Typography>
                                    <Typography variant="body_long">{Localization.SEARCH_TIP_5}</Typography>
                                    <Typography variant="body_short" color="primary">
                                        {Localization.SEARCH_EXAMPLE_5}
                                    </Typography>
                                </div>
                                <div className={styles.answer}>
                                    <Typography variant="body_short_bold">| {Localization.OPERATOR}</Typography>
                                    <Typography variant="body_long">{Localization.SEARCH_TIP_6}</Typography>
                                    <Typography variant="body_short" color="primary">
                                        {Localization.SEARCH_EXAMPLE_6}
                                    </Typography>
                                </div>
                            </Accordion.Panel>
                        </Accordion.Item>
                        <Accordion.Item>
                            <Accordion.Header>{Localization.FAQ_FAVORITES_SAVING_QUESTION}</Accordion.Header>
                            <Accordion.Panel>{Localization.FAQ_FAVORITES_SAVING_ANSWER}</Accordion.Panel>
                        </Accordion.Item>
                        <Accordion.Item>
                            <Accordion.Header>{Localization.FAQ_FAVORITES_PDF_QUESTION}</Accordion.Header>
                            <Accordion.Panel>{Localization.FAQ_FAVORITES_PDF_ANSWER}</Accordion.Panel>
                        </Accordion.Item>
                        <Accordion.Item>
                            <Accordion.Header>{Localization.FAQ_CONTACT_QUESTION}</Accordion.Header>
                            <Accordion.Panel>
                                <ul>
                                    <li>Rikke Bang Landmark</li>
                                    <li>Ingerid Gjeitnes Hellen</li>
                                </ul>
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </MainContent>
            <SideMenu></SideMenu>
        </div>
    );
};
