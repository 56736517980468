import LocalizedStrings from 'react-localization';
import en from './localization_en';
import no from './localization_no';
import po from './localization_po';

export const Localization = new LocalizedStrings({
    en,
    no,
    po
});
