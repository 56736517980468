export default {
    SELECT_LANGUAGE: 'Velg språk',
    SEARCH: 'Søk',
    CONTACT_SAFEX_TEAM: 'Kontakt SafeX Team',
    VERSION: 'Versjon',
    FAVORITES: 'Favoritter',
    HOME: 'Hjem',
    HIDE_FILTERS: 'Skjul filtre',
    SHOW_FILTERS: 'Vis filtre',
    DELETE_FILTERS: 'Fjern filtre',
    SOURCE: 'Kilde',
    LOCATION: 'Lokasjon',
    DATE: 'Dato',
    OTHER: 'Andre',
    SEVERITY: 'Alvorlighet',
    FROM: 'Fra',
    TO: 'Til',
    SORT_BY_DATE: 'Sorter etter nyeste',
    INSTALLATION: 'Installasjon',
    SHORE: 'Land/Hav',
    SELECTION: 'Valg',
    ACTIVITY: 'Aktivitet',
    WORK_PROCESS: 'Arbeidsprosess',
    RESPONSIBLE_UNIT: 'Ansvarlig enhet',
    QUERY_TIPS: 'Søketips',
    RISK_MOMENTS: 'Årsaksfaktorer',
    IMPORT: 'Importer',
    EXPORT: 'Eksporter',
    IMPORT_EXPORT_DESCRIPTION:
        'Favorittene dine er kun lagret i browseren så hvis du ønsker å ta vare på dem eller flytt mellom datamaskiner kan du eksoprtere og importere dem',
    RISK1: 'Uønsket atferd (interne og eksterne)',
    RISK2: 'Feil/mangel på system/utstyr',
    RISK3: 'Tekniske feil eller svikt på komponent/ system/anlegg',
    RISK4: 'Mangelfull oppmerksomhet/aktsomhet',
    RISK5: 'Krav/retningslinjer/instrukser ikke fulgt',
    DOWNLOAD_PDF: ' Last ned som PDF',
    GENERATED_ON: 'Generert den',
    REPORT_FROM: 'Rapport fra',
    HOW_TO_SEARCH: 'Hvordan søke?',
    HOW_TO_USE_FAVORITES: 'Hvordan bruke favoritter?',
    SIMPLE_SEARCH: 'Enkelt søk',
    SEARCH_TIP_1: 'Søk etter ett eller flere av ordene. Vil returnere treff der ett av ordene finnes.',
    SEARCH_EXAMPLE_1: 'Eksempel: brann ulykke bil',
    OPERATOR: 'operator',
    SEARCH_TIP_2:
        ' Der man ønsker at et ord skal være med i resultatet (NB! Det første ordet er i dette tilfellet automatisk med +)',
    SEARCH_EXAMPLE_2: 'Eksempel: brann +ulykke',
    SEARCH_TIP_3: 'Der man ønsker å ikke ha med resultater med enkelte ord.',
    SEARCH_EXAMPLE_3: 'Eksempel: brann -bil',
    QUOTES: 'anførselstegn',
    SEARCH_TIP_4: 'brukes for å søke etter en bestemt frase.',
    SEARCH_EXAMPLE_4: 'Eksempel: &quot;ulykke med bil&quot;',
    SEARCH_TIP_5: 'Brukes for å søke på deler av et langt ord.',
    SEARCH_EXAMPLE_5: 'Eksempel: olje*',
    SEARCH_TIP_6: 'Søker på enten det ene eller andre ordet',
    SEARCH_EXAMPLE_6: 'Eksempel: brann | bil',
    FAQ_FAVORITES_SAVING_QUESTION: 'Hvordan lagre en hendelse som favoritt?',
    FAQ_FAVORITES_SAVING_ANSWER:
        'Du kan legge til en hendelse til dine favoritter ved å velge en hendelse og deretter trykke på Lagre favoritt-knappen. Hendelsen vil deretter dukke opp på Favoritter-siden. Merk at favorittene dine kun lagres i nettleserens minne, så bruk eksport- og importfunksjonaliteten hvis du ønsker å ta bedre vare på dem. Eksport og import kan også brukes til å dele dine favoritter med andre.',
    FAQ_FAVORITES_PDF_QUESTION: 'Hvordan laste ned hendelser som PDF?',
    FAQ_FAVORITES_PDF_ANSWER:
        'Start med å legge til hendelsene du vil laste ned som favoritter. Gå deretter til Favoritter-siden via linken i høyre sidepanel og trykk på Last ned som PDF-knappen.',
    FAQ_WHAT_IS_SAFEX_QUESTION: 'Hva er SafeX?',
    FAQ_WHAT_IS_SAFEX_ANSWER:
        'Tanken med SafeX er å samle alle sikkerhetshendelser på én plattform og med det gjøre dem mer tilgjengelige. På nåværende tidspunkt vises kun saker fra SIOP og Synergi, men det kan bli flere kilder etter hvert. I tillegg til nettsiden du leser dette på, består SafeX av et API som enkelt kan integreres i andre Equinor-løsninger.',
    FAQ_CONTACT_QUESTION: 'Hvem drifter SafeX?',
    ANALYTICS: 'Analyse',
    CLOSE: 'Lukk',
    SEARCH_SIMILAR: 'Finn lignende',
    SAVE_FAVORITE: 'Lagre favoritt',
    REMOVE_FAVORITE: 'Fjern favoritt',
    SHOW_ORIGINAL_LANGUAGE: 'Vis originalen',
    SHOW_TRANSLATED_LANGUAGE: 'Vis engelsk',
    ACTIONS: 'Aksjoner',
    EXTERNAL_SOURCES: 'Eksterne kilder',
    CASE: 'Sak',
    FREQUENT_SEARCH: ' Vanlige søk',
    FREQUENT_SEARCH_FIRE: 'Brann',
    FREQUENT_SEARCH_NEWEST: 'Nyeste',
    FREQUENT_SEARCH_SEVERE: 'Alvorlige',
    FREQUENT_SEARCH_VIBRATIONS: 'Vibrasjoner',
    FREQUENT_SEARCH_FALL: 'Fall',
    FREQUENT_SEARCH_COVID19: ' Covid 19',
    ADVANCED_QUERY_INFO_HEADER: 'Bruk operatorer for avanserte søk',
    ADVANCED_QUERY_INFO_PLUS: 'for et ord som må være med',
    ADVANCED_QUERY_INFO_MINUS: 'for ord som ikke skal finnes',
    ADVANCED_QUERY_INFO_VERTICAL_BAR: 'for eller',
    ADVANCED_QUERY_INFO_QUOTES: 'for eksakt treff på tekst',
    ADVANCED_QUERY_INFO_ASTERISK: 'for alle hendelser',
    SAFEX_DATA_INTERNAL: 'SafeX data er klassifisert som Intern',
    CAUSE_CATEGORY: 'Årsakskategori',
    CAUSE: 'Årsak',
    HOW_REVEALED: 'Hvordan oppdaget',
    KEY_LESSON: 'Viktigste læring',
    CLASSIFICATION_COMMENT: 'Klassifikasjonskommentar',
    COUNTRY: 'Land'
};
