import { FacetResult } from '@sdl/sdl-api-client';
import cx from 'classnames';
import CSS from 'csstype';
import * as _ from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Word } from 'react-wordcloud';
import { GlobalContext } from '../../state/context';
import { SafexWordCloud } from '../../structure/page/SafexWordCloud';
import styles from './CloudWrapper.module.css';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

const getWordCount = (facet: FacetResult[]): Word[] => {
    return facet.map((x) => ({ text: x.value.split(' - ').pop() ?? '', value: x.count, id: x.value }));
};

export const CloudWrapper = (props: IProps): JSX.Element => {
    const { state } = useContext(GlobalContext);
    const [words, setWords] = useState<Word[]>([]);

    const getWords = useCallback(() => {
        const w = getWordCount(state.searchResponse.causeFacets);
        if (!_.isEqual(w, words)) {
            setWords(w);
        }
    }, [state.searchResponse.causeFacets, words]);

    useEffect(() => {
        getWords();
    }, [getWords]);

    return (
        <div className={cx(styles.CloudWrapper, props.className)} style={props.style}>
            <SafexWordCloud className={styles.SafexWordCloud} words={words}></SafexWordCloud>
        </div>
    );
};
