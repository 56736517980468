import { Icon } from '@equinor/eds-core-react';
import cx from 'classnames';
import CSS from 'csstype';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { ISelectFieldOptions, SelectField } from '../../element/input/SelectField';
import { Localization } from '../../localization/localization';
import { GlobalContext } from '../../state/context';
import { language } from '../../state/user/userActions';
import { MetaInfo } from '../page/MetaInfo';
import styles from './SideMenu.module.css';
import { ESidePanelSide, ESidePanelSize, SidePanel } from './SidePanel';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

export const SideMenu = (props: IProps): JSX.Element => {
    const { dispatch, state } = useContext(GlobalContext);

    const [opened, setOpen] = useState<boolean>(false);

    function handleLanguageSelect(selection: ISelectFieldOptions | ISelectFieldOptions[] | null): void {
        const s = selection as ISelectFieldOptions;
        localStorage.setItem('safex_language', s.value);
        dispatch(language(s.value));
        Localization.setLanguage(s.value);
        location.reload();
    }

    return (
        <div className={cx(styles.SideMenu, props.className)} style={props.style}>
            <div
                className={cx(styles.badge, opened ? styles.opened : styles.closed)}
                onClick={(): void => setOpen(!opened)}
                onKeyDown={(event): void => {
                    if (event.key == 'Enter') {
                        setOpen(!opened);
                    }
                }}
                tabIndex={0}
            >
                {opened && <Icon name="close" title={'Close'}></Icon>}
                {!opened && <span>SX</span>}
            </div>
            {opened && (
                <SidePanel size={ESidePanelSize.SMALL} side={ESidePanelSide.RIGHT} className={styles.SidePanel}>
                    <div className={styles.items}>
                        <Link to="/" className={styles.item}>
                            <span className={styles.initials}>SX</span>
                            <span className={styles.text}>{Localization.HOME}</span>
                        </Link>
                        <Link to="/faq" className={styles.item}>
                            <span className={styles.icon}>
                                <Icon name="help_outline" title={'FAQ'}></Icon>
                            </span>
                            <span className={styles.text}>FAQ</span>
                        </Link>
                        <Link to="/favorites" className={styles.item}>
                            <span className={styles.icon}>
                                <Icon name="star_outlined" title={'Favorites'}></Icon>
                            </span>
                            <span className={styles.text}>{Localization.FAVORITES}</span>
                        </Link>
                    </div>
                    <SelectField
                        label={Localization.SELECT_LANGUAGE}
                        selectedOptions={
                            state.language === 'en'
                                ? [
                                      {
                                          value: 'en',
                                          label: 'English'
                                      }
                                  ]
                                : state.language === 'po'
                                ? [{ value: 'po', label: 'Portuguese' }]
                                : [
                                      {
                                          value: 'no',
                                          label: 'Norsk'
                                      }
                                  ]
                        }
                        placeholder="Selection"
                        isMulti={false}
                        onSelectedItem={handleLanguageSelect}
                        className={styles.SelectField}
                        options={[
                            { value: 'en', label: 'English' },
                            { value: 'no', label: 'Norsk' },
                            { value: 'po', label: 'Portuguese' }
                        ]}
                    ></SelectField>
                    <div className={styles.meta}>
                        <MetaInfo></MetaInfo>
                    </div>
                </SidePanel>
            )}
        </div>
    );
};
