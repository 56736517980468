import * as React from 'react';

export function getHighlightedText(text: string | undefined, search: string): React.ReactNode {
    if (!search || search == '*') return <>{text}</>;
    const highlights = getHighlightsFromSearchString(search);
    if (highlights.length > 5) return <>{text}</>;
    if (!text) return <></>;
    const parts = isolateHighlightsFromText(text, highlights);
    return (
        <span>
            {parts.map((part, i) => {
                return checkAllParts(part, highlights) ? (
                    <span key={i}>
                        <mark className="highlight">{part}</mark>
                    </span>
                ) : (
                    <span key={i}>{part}</span>
                );
            })}
        </span>
    );
}

function getHighlightsFromSearchString(search: string) {
    const regexpFromQuotes = /"(.*?)"/g;
    const textFromQuotes = [...search.matchAll(regexpFromQuotes)].map((x) => x[1]).flat();
    let searchWithoutQuotes = search;
    textFromQuotes.forEach((quote) => (searchWithoutQuotes = searchWithoutQuotes.replace(quote, '')));
    return searchWithoutQuotes
        .replaceAll(/\+/g, ' ')
        .replaceAll(/\|/g, ' ')
        .replaceAll(/[&\/\\#,+()$~%.'":*?<>^{}\[\]]/g, '')
        .split(' ')
        .filter((w) => w.length && !w.includes('-'))
        .concat(textFromQuotes);
}

function isolateHighlightsFromText(text: string, highlights: string[]) {
    let parts = [text];
    for (const h of highlights) {
        parts = parts
            .map((x) => x.split(new RegExp('(' + h + ')', 'i')))
            .flat()
            .filter((w) => w.length);
    }
    return parts;
}

function checkAllParts(part: string, highlights: string[]) {
    for (const h of highlights) {
        if (h.toLowerCase() === part.toLowerCase()) {
            return true;
        }
    }
    return false;
}
