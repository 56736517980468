export default {
    SELECT_LANGUAGE: 'Selecione o idioma',
    SEARCH: 'Pesquisar',
    CONTACT_SAFEX_TEAM: 'Entre em contato com a equipe SafeX',
    VERSION: 'Versão',
    FAVORITES: 'Favoritos',
    HOME: 'Início',
    HIDE_FILTERS: 'Ocultar filtros',
    SHOW_FILTERS: 'Mostrar filtros',
    DELETE_FILTERS: 'Limpar filtros',
    SOURCE: 'Fonte',
    LOCATION: 'Localização',
    DATE: 'Data',
    OTHER: 'Outros',
    SEVERITY: 'Gravidade',
    FROM: 'De',
    TO: 'Para',
    SORT_BY_DATE: 'Classificar por recente',
    INSTALLATION: 'Instalação',
    SHORE: 'Onshore/Offshore',
    SELECTION: 'Seleção',
    ACTIVITY: 'Atividade',
    WORK_PROCESS: 'Processo de trabalho',
    RESPONSIBLE_UNIT: 'Unidade responsável',
    QUERY_TIPS: 'Dicas de busca',
    RISK_MOMENTS: 'Fatores de causa',
    IMPORT: 'Importar',
    EXPORT: 'Exportar',
    IMPORT_EXPORT_DESCRIPTION:
        'Seus favoritos são armazenados apenas no cache deste navegador. Se quiser mantê-los por mais tempo ou transferi-los entre computadores, você pode exportá-los e importá-los.',
    RISK1: 'Comportamento indesejado (interno e externo)',
    RISK2: 'Falha/defeito no sistema/equipamento',
    RISK3: 'Defeito ou falhas técnicas no componente/sistema/planta',
    RISK4: 'Descuidado/desfocado/não observador/distraído',
    RISK5: 'Não atendeu aos requisitos/diretrizes/instruções',
    DOWNLOAD_PDF: 'Baixe como PDF',
    GENERATED_ON: 'Gerado em',
    REPORT_FROM: 'Relatório de',
    HOW_TO_SEARCH: 'Como pesquisar?',
    HOW_TO_USE_FAVORITES: 'Como usar os favoritos?',
    SIMPLE_SEARCH: 'Pesquisa simples',
    SEARCH_TIP_1: 'Pesquisando por qualquer uma das palavras, dará resultado também quando apenas uma for encontrada.',
    SEARCH_EXAMPLE_1: 'Exemplo: fogo acidente carro',
    OPERATOR: 'operador',
    SEARCH_TIP_2:
        'É usado quando todas as palavras precisam aparecer nos resultados da pesquisa (NB! A primeira palavra é obrigatória automaticamente)',
    SEARCH_EXAMPLE_2: 'Exemplo: fogo +acidente +carro',
    SEARCH_TIP_3:
        'É usado onde algumas palavras não devem estar presentes (por exemplo, não deve incluir carro: -carro))',
    SEARCH_EXAMPLE_3: 'Exemplo: fogo -carro',
    QUOTES: 'citações',
    SEARCH_TIP_4: 'é usado para buscar uma frase específica',
    SEARCH_EXAMPLE_4: 'Exemplo: &quot;acidente de carro&quot;',
    SEARCH_TIP_5:
        ' É usado se você deseja pesquisar uma palavra que pode ter várias extensões ou fazer parte de uma palavra mais longa.',
    SEARCH_EXAMPLE_5: 'Exemplo: pressione*',
    SEARCH_TIP_6: 'É usado se você deseja pesquisar uma palavra ou outra.',
    SEARCH_EXAMPLE_6: 'Exemplo: fogo | carro',
    FAQ_FAVORITES_SAVING_QUESTION: 'Como salvar um incidente como favorito?',
    FAQ_FAVORITES_SAVING_ANSWER:
        'Você pode adicionar um incidente aos seus favoritos selecionando um caso e clicando no botão Salvar favorito. O incidente aparecerá na página Favoritos. Observe que seus favoritos são armazenados apenas na memória do navegador. Para preservá-los em um local mais seguro, use a funcionalidade de exportação e importação. A funcionalidade de importação/exportação também pode ser usada para compartilhar seus favoritos com outras pessoas.',
    FAQ_FAVORITES_PDF_QUESTION: 'Como baixar incidentes como PDF?',
    FAQ_FAVORITES_PDF_ANSWER:
        'Primeiro, adicione os incidentes que deseja baixar como favoritos. Em seguida, vá para a página Favoritos através do link no painel do lado direito e clique no botão Baixar como PDF.',
    FAQ_WHAT_IS_SAFEX_QUESTION: 'O que é SafeX?',
    FAQ_WHAT_IS_SAFEX_ANSWER:
        'A ideia por trás do SafeX é reunir todos os tipos de incidentes de segurança em uma plataforma e torná-los mais acessíveis. Atualmente, apenas os casos Synergi e SIOP estão incluídos, mas mais podem vir. Além da página da Web em que você está lendo isso, o SafeX consiste em uma API que pode ser facilmente integrada a outros serviços da Equinor.',
    FAQ_CONTACT_QUESTION: 'Quem é responsável pelo SafeX?',
    ANALYTICS: 'Análise',
    CLOSE: 'Fechar',
    SEARCH_SIMILAR: 'Encontrar semelhante',
    SAVE_FAVORITE: 'Salvar favorito',
    REMOVE_FAVORITE: 'Remover favorito',
    SHOW_ORIGINAL_LANGUAGE: 'Ver original',
    SHOW_TRANSLATED_LANGUAGE: 'Ver português',
    ACTIONS: 'Ações',
    EXTERNAL_SOURCES: 'Fontes externas',
    CASE: 'Caso',
    FREQUENT_SEARCH: 'Pesquisas frequentes',
    FREQUENT_SEARCH_FIRE: 'Fogo',
    FREQUENT_SEARCH_NEWEST: 'Mais recente',
    FREQUENT_SEARCH_SEVERE: 'Grave',
    FREQUENT_SEARCH_VIBRATIONS: 'Vibrações',
    FREQUENT_SEARCH_FALL: 'Outono',
    FREQUENT_SEARCH_COVID19: 'Covid 19',
    ADVANCED_QUERY_INFO_HEADER: 'Use operadores para pesquisas avançadas',
    ADVANCED_QUERY_INFO_PLUS: 'para uma palavra necessária',
    ADVANCED_QUERY_INFO_MINUS: 'para palavras que não devem ocorrer',
    ADVANCED_QUERY_INFO_VERTICAL_BAR: 'para qualquer',
    ADVANCED_QUERY_INFO_QUOTES: 'para texto de correspondência exata',
    ADVANCED_QUERY_INFO_ASTERISK: 'para todos os incidentes',
    SAFEX_DATA_INTERNAL: 'Os dados SafeX são classificados como internos',
    CAUSE_CATEGORY: 'Categoria de causa',
    CAUSE: 'Causa',
    HOW_REVEALED: 'Como revelado',
    KEY_LESSON: 'Lição principal',
    CLASSIFICATION_COMMENT: 'Comentário de classificação',
    COUNTRY: 'País'
};
