import { ActionType, StateActions } from '../actions';
import { IGlobalState } from '../state';
import { initialPageState } from './pageState';

export function pageReducer(state: IGlobalState, action: StateActions): IGlobalState | null {
    switch (action.type) {
        case ActionType.ResetPage:
            return {
                ...state,
                ...initialPageState
            };
        case ActionType.SearchParameters:
            return {
                ...state,
                searchParameters: action.payload.value
            };
        case ActionType.SelectedCase:
            return {
                ...state,
                selectedCase: action.payload.value
            };
        case ActionType.SearchResponse:
            return {
                ...state,
                searchResponse: action.payload.value
            };
        case ActionType.Searching:
            return {
                ...state,
                searching: action.payload.value
            };
        case ActionType.HideFilters:
            return {
                ...state,
                hideFilters: action.payload.value
            };
        default:
            return null;
    }
}
