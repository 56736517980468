import {
    ErrorResponse,
    ErrorResponseFromJSON,
    FetchParams,
    Middleware,
    RequestContext,
    ResponseContext
} from '@sdl/sdl-api-client';
import { pca } from '../services/auth/pca';

export class BearerTokenMiddleware implements Middleware {
    async pre(context: RequestContext): Promise<FetchParams | void> {
        const auth = await pca.acquireTokenSilent({
            scopes: ['api://safex-api/Incidents.Read']
        });
        const accessToken = auth.accessToken;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const headers: any = context.init.headers || {};
        headers.Authorization = 'Bearer ' + accessToken;
        context.init.headers = headers;
        return context;
    }
}

export class ErrorPropagationMiddleware implements Middleware {
    async post(context: ResponseContext): Promise<Response | void> {
        const response = context.response.clone();
        if (!response.ok) {
            const json = await response.json();
            const errorResponse: ErrorResponse = ErrorResponseFromJSON(json);
            additionalErrorHandling(errorResponse);
        }
        return context.response;
    }
}

const additionalErrorHandling = (errorResponse: ErrorResponse) => {
    console.log(`Api error: `, errorResponse);
};
