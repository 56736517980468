import { toast, ToastPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function toastify(type: 'error' | 'success', text: string): void {
    const body = {
        position: 'top-right' as ToastPosition,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    };
    if (type === 'error') {
        toast.error(text, body);
    } else if (type === 'success') {
        toast.success(text, body);
    }
}
