import cx from 'classnames';
import CSS from 'csstype';
import React from 'react';
import { DateFilters } from './DateFilters';
import styles from './Filters.module.css';
import { LocationFilters } from './LocationFilters';
import { OtherFilters } from './OtherFilters';
import { SourceFilters } from './SourceFilters';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

export const Filters = (props: IProps): JSX.Element => {
    return (
        <div className={cx(styles.Filters, props.className)} style={props.style}>
            <>
                <SourceFilters />
                <LocationFilters />
                <DateFilters />
                <OtherFilters />
            </>
        </div>
    );
};
