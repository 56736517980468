import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import React, { JSXElementConstructor, ReactElement, ReactNode } from 'react';
import { TeamsLink } from '../element/TeamsLink';
import { reactPlugin } from './../services/log/AppInsights';
import styles from './ErrorBoundary.module.css';

interface IProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: ReactElement<any, string | JSXElementConstructor<any>> & ReactNode;
}

const ErrorBoundary = (props: IProps): JSX.Element => {
    return (
        <AppInsightsErrorBoundary
            onError={() => (
                <div className={styles.ErrorBoundary}>
                    <h1>An unexpected error occurred.</h1>
                    <span>
                        Unless you have enabled an adblocker in your browser, we should already be notified of the
                        error. Please <TeamsLink text={'contact the SafeX team'}></TeamsLink> if you need further
                        assistance.
                    </span>
                </div>
            )}
            appInsights={reactPlugin}
        >
            {props.children}
        </AppInsightsErrorBoundary>
    );
};

export default ErrorBoundary;
