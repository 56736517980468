import cx from 'classnames';
import CSS from 'csstype';
import React from 'react';
import styles from './SeverityBadge.module.css';

export enum ESeverity {
    A = 1,
    B = 2,
    C = 3,
    D = 4,
    E = 5
}

export interface IProps {
    severity: ESeverity;
    style?: CSS.Properties;
    className?: string;
}

export const SeverityBadge = (props: IProps): JSX.Element => {
    return (
        <div
            className={cx(styles.SeverityBadge, props.className, styles[ESeverity[props.severity]])}
            style={props.style}
        >
            <span>{props.severity}</span>
        </div>
    );
};
