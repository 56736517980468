import { Icon, Popover, Typography } from '@equinor/eds-core-react';
import cx from 'classnames';
import React, { useRef, useState } from 'react';
import { Localization } from '../../localization/localization';
import styles from './AdvancedQueryInfo.module.css';
import { IProps } from './MetaInfo';

export const AdvancedQueryInfo = (props: IProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    return (
        <div className={cx(styles.AdvancedQueryInfo, props.className)} style={props.style}>
            <div
                className={styles.advanced}
                onClick={() => setIsOpen(!isOpen)}
                tabIndex={0}
                onKeyDown={(event): void => {
                    if (event.key == 'Enter') {
                        setIsOpen(!isOpen);
                    }
                }}
            >
                <div>
                    <Icon name={'info_circle'} title={'Primary'}></Icon>
                </div>
                <div ref={anchorRef} className={styles.advancedtext}>
                    <Typography>{Localization.QUERY_TIPS}</Typography>
                </div>
            </div>
            <Popover
                placement="bottom-end"
                id="click-popover"
                aria-expanded={isOpen}
                anchorEl={anchorRef.current}
                onClose={() => setIsOpen(!isOpen)}
                open={isOpen}
            >
                <Popover.Content>
                    <Typography variant="body_long" className={styles.tips}>
                        {Localization.ADVANCED_QUERY_INFO_HEADER} <br></br>
                        <b>+</b> {Localization.ADVANCED_QUERY_INFO_PLUS} <br></br>
                        <b>-</b> {Localization.ADVANCED_QUERY_INFO_MINUS} <br></br>
                        <b>|</b> {Localization.ADVANCED_QUERY_INFO_VERTICAL_BAR} <br></br>
                        <b>&quot; &quot;</b> {Localization.ADVANCED_QUERY_INFO_QUOTES} <br></br>
                        <b>*</b> {Localization.ADVANCED_QUERY_INFO_ASTERISK}
                    </Typography>
                </Popover.Content>
            </Popover>
        </div>
    );
};
