import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { DotProgress } from '@equinor/eds-core-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { Md5 } from 'ts-md5/dist/md5';
import { TeamsLink } from '../../element/TeamsLink';
import { pca } from '../../services/auth/pca';
import { appInsights } from '../../services/log/AppInsights';
import styles from './Auth.module.css';

interface LoginProps {
    children: ReactNode;
}

const Auth = ({ children }: LoginProps): JSX.Element => {
    const accounts = pca.getAllAccounts();

    const [init, setInit] = useState<boolean>(false);
    const [reloadMessage, setReloadMessage] = useState<boolean>(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setReloadMessage(true);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (accounts && accounts[0] && !init) {
            console.log('Logged', accounts);
            pca.setActiveAccount(accounts[0]);
            const properties = {
                user: Md5.hashStr('safex' + accounts[0].name),
                hostName: window.location.hostname
            };

            appInsights.trackEvent({
                name: window.location.hostname + '_SAFEX_START',
                properties: properties
            });
            setInit(true);
        }
    }, [accounts, init]);

    function ErrorComponent(e: unknown) {
        console.log('er', e);
        appInsights.trackEvent({ name: window.location.hostname + '_SAFEX_START_NOTLOGGEDIN' });
        return (
            <p>
                An Authentification Error Occurred, please <TeamsLink text={'contact the SafeX team'}></TeamsLink> or
                try refreshing the page
            </p>
        );
    }

    function LoadingComponent() {
        return (
            <div className={styles.Auth}>
                <DotProgress size={64} color="primary" />
                {reloadMessage && (
                    <span>
                        If the authentification takes too long, try refreshing the browser or{' '}
                        <TeamsLink text={'contact the SafeX team'}></TeamsLink>
                    </span>
                )}
            </div>
        );
    }

    const authRequest = {
        scopes: ['user.read']
    };

    return (
        <>
            <MsalProvider instance={pca}>
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={authRequest}
                    errorComponent={ErrorComponent}
                    loadingComponent={LoadingComponent}
                >
                    {init && <> {children}</>}
                    {!init && <>{LoadingComponent()}</>}
                </MsalAuthenticationTemplate>
            </MsalProvider>
        </>
    );
};

export default Auth;
