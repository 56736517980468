import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { clientId, tenantId } from '../../config/ids';

const config: Configuration = {
    auth: {
        authority: 'https://login.microsoftonline.com/' + tenantId, // must to be the issuer URL
        clientId: clientId,
        redirectUri: window.location.origin + '/logincallback'
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true // Fixes issues in Edge due to cleaning of cache.
    }
};

export const pca = new PublicClientApplication(config);
