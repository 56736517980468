import cx from 'classnames';
import CSS from 'csstype';
import React, { useContext } from 'react';
import { FilterBox } from '../../element/filter/FilterBox';
import { useSearch } from '../../hook/useSearch';
import { Localization } from '../../localization/localization';
import { GlobalContext } from '../../state/context';
import { searchParameters } from '../../state/page/pageActions';
import { initialPageState } from '../../state/page/pageState';
import styles from './OtherFilters.module.css';
import { SeverityFilter } from './SeverityFilter';
import { SourceFilter } from './SourceFilter';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

export const OtherFilters = (props: IProps): JSX.Element => {
    const { state, dispatch } = useContext(GlobalContext);
    const searchHook = useSearch();

    return (
        <div className={cx(styles.OtherFilters, props.className)} style={props.style}>
            <FilterBox
                title={Localization.OTHER}
                onDelete={() => {
                    const params = {
                        ...state.searchParameters,
                        severityFilter: initialPageState.searchParameters.severityFilter,
                        sourceFilter: initialPageState.searchParameters.sourceFilter
                    };
                    dispatch(searchParameters(params));
                    searchHook.search(params);
                }}
            >
                <SeverityFilter></SeverityFilter>
                <SourceFilter></SourceFilter>
            </FilterBox>
        </div>
    );
};
