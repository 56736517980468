export enum EFacet {
    RESPONSIBLE_UNIT,
    WORK_PROCESS,
    ACTIVITY,
    COUNTRY,
    CAUSE,
    INSTALLATION,
    SHORE,
    SEVERITY,
    SOURCE
}

export function getFacetKey(key: EFacet, languageCode: string): string {
    if (key === EFacet.RESPONSIBLE_UNIT) {
        return 'resp_unit_level1_org';
    }
    if (key === EFacet.WORK_PROCESS) {
        switch (languageCode) {
            case 'en':
                return 'work_process_level1_eng';
            case 'po':
                return 'work_process_level1_por';
            default:
                return 'work_process_level1_org';
        }
    }
    if (key === EFacet.ACTIVITY) {
        switch (languageCode) {
            case 'en':
                return 'activity_level1_eng';
            case 'po':
                return 'activity_level1_por';
            default:
                return 'activity_level1_org';
        }
    }
    if (key === EFacet.COUNTRY) {
        switch (languageCode) {
            case 'en':
                return 'country_eng';
            case 'po':
                return 'country_por';
            default:
                return 'country_org';
        }
    }
    if (key === EFacet.CAUSE) {
        switch (languageCode) {
            case 'en':
                return 'cause_eng';
            case 'po':
                return 'cause_por';
            default:
                return 'cause_org';
        }
    }
    if (key === EFacet.INSTALLATION) {
        switch (languageCode) {
            case 'en':
                return 'installation_eng';
            case 'po':
                return 'installation_por';
            default:
                return 'installation_org';
        }
    }
    if (key === EFacet.SHORE) {
        switch (languageCode) {
            case 'en':
                return 'land_ocean_eng';
            case 'po':
                return 'land_ocean_por';
            default:
                return 'land_ocean_org';
        }
    }
    if (key === EFacet.SEVERITY) {
        return 'severity';
    }
    if (key === EFacet.SOURCE) {
        return 'source';
    }
    return '';
}
