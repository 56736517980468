import { HideFilters, ResetPage, Searching, SearchParameters, SearchResponse, SelectedCase } from './page/pageActions';
import { Language } from './user/userActions';

export enum ActionType {
    ResetPage,
    SearchParameters,
    SelectedCase,
    SearchResponse,
    Searching,
    Language,
    HideFilters
}

export type StateActions =
    | ResetPage
    | SearchParameters
    | SearchResponse
    | Searching
    | Language
    | SelectedCase
    | HideFilters;
